import React from 'react';
import styled from 'styled-components';
import {NavigationLink} from '../NavigationLink';

//importing images

import Banksy from '../../assets/ArtsImages/Banksy_painting.jpg';
import Wes2 from '../../assets/ArtsImages/WesAnderson2.jpg';
import Wes_for_arts from '../../assets/ArtsImages/wes-for-arts.jpg';
import van_goh_for_arts from '../../assets/ArtsImages/van-Goh-for-arts.jpg';
import pop_art_for_art from '../../assets/ArtsImages/pop-art-for-art.jpg';




const Wrapper = styled.div`
    height: 100vh;
    width:100%;
    background-color:#009178;
    color: #C7FCEC;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center; 
    text-align:center;
    overflow-x: hidden;
    @media (max-width: 800px) {
        flex-direction: column;
        padding-bottom: 10px;
        padding-top:10px;
    }
`;

const FirstColumn = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width:50%;
    @media(min-width:800px){
        padding-left:20px;

    }    
    @media(max-width: 800px){
    }
    overflow: hidden;
`;

const SecondColumn = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    @media(max-width: 800px){
        width:90%;
    }
    overflow: hidden;
`;

const Header = styled.h1`
    font-size:4rem;
    height:2rem;
    font-family: 'MikelaRegular';
    @media( max-width: 800px){
        font-size:2.5rem;
    }
`;
const Text = styled.p`
    font-size:2.5rem;
    font-style: italic;
    @media( max-width: 800px){
        font-size:1rem;
        margin-top: -10px;
    }
`;

const Card = styled.img`
    height:inherit;
    width:inherit;
    background-color:lightgrey;
    border-radius: 50px;
    aspect-ratio : 1 / 1;
    @media( max-width: 800px){
        border-radius: 30px;
    }
`;

const ImageContainer = styled.div`
    position: relative;
    text-align: center;
    color: white;
    aspect-ratio : 1 / 1;
    margin-bottom: 10px;

`;

const FirstImageColumn = styled.div`
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px 0px;

    @media(max-width:800px){
        width: 49%;
    }
`;
const SecondImageColumn = styled.div`
    padding-left:20px;
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media(max-width:800px){
        width: 49%;
        padding-top: 20px;
    }
`;
const FirstImageFirstColumn = styled(ImageContainer)`
    margin-right:-40px;
    height:40vh;
    font-size:2.3rem;
    @media( max-width: 800px){
        height: 24vh;
        margin-right:-40px;
        font-size:1.2rem;
    }
    
`;
const SecondImageFirstColumn = styled(ImageContainer)`
    height:20vh;
    font-size:1.9rem;
    @media( max-width: 800px){
        height:18vh;
        margin-right:0px;
        font-size:1.5rem;
    }
`;
const FirstImageSecondColumn = styled(ImageContainer)`
    height:30vh;
    margin-left:70px;
    font-size:2.5rem;

    @media( max-width: 800px){
        height: 15vh;
        margin-left:30px;
        font-size:1.2rem;
    }
`;
const SecondImageSecondColumn = styled(ImageContainer)`
    height:20vh;
    margin-left:30px;
    font-size:2.2rem;
    @media(max-width: 800px){
        height: 16vh;
        font-size:0.9rem;
    }
`;
const ThirdImageSecondColumn = styled(ImageContainer)`
    height:25vh;
    font-size:1.3rem;
    margin-left: 30px;
    
    @media( max-width: 800px){
        height:20vh;
        margin-left:-15px;
        font-size:2rem;
    }
`;
const ImageText = styled.p`
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-45%, -50%);
    width: 100%;
    font-weight:1000;
    text-align:center;
    opacity:0.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(0,0,0,0.2);
`;
const StyledNavigation = styled(NavigationLink)`
    background-color:#C7FCEC;
    color: #009178;
    border-color:green;
    display:block;
    width:auto;
    @media(min-width:800px){
        width: 30%;
        align-self: center;
    }
    &:hover{
        color: darkgreen;
    }
`;
const SecondWrapper = styled.div`
    @media(max-width: 800px){
        position: absolute;
        width: 100%;
    }

    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center; 
    text-align:center;
    overflow-x: hidden;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;




const Arts = () =>{

    return(
        <Wrapper>
            <SecondWrapper>
            <FirstColumn>
                <Header> Arts </Header>
                <Text> You can now loose an ear like Van Goh!!! </Text>
                <p style={{ fontSize: '1.2rem', marginTop:'-15px' }}> (In a painting of course) </p>
                <StyledNavigation variant='primary' to='/create'> Become Art </StyledNavigation>

            </FirstColumn>
            <SecondColumn>
                <FirstImageColumn>
                    <FirstImageFirstColumn>
                        <Card  src={Wes_for_arts}/>
                        <ImageText>Wes Anderson</ImageText>
                    </FirstImageFirstColumn>

                    <SecondImageFirstColumn>
                        <Card src={pop_art_for_art}/>
                        <ImageText>Pop Art</ImageText>
                    </SecondImageFirstColumn>
                </FirstImageColumn>

                <SecondImageColumn>

                    <FirstImageSecondColumn>
                        <Card src={ van_goh_for_arts}/>
                        <ImageText>Van Gogh</ImageText>
                    </FirstImageSecondColumn>

                    <SecondImageSecondColumn>
                        <Card  src={Wes2}/>
                        <ImageText>Wes Anderson</ImageText>
                    </SecondImageSecondColumn>
                    
                    <ThirdImageSecondColumn>
                        <Card src={Banksy}/>
                        <ImageText>Banksy</ImageText>
                    </ThirdImageSecondColumn>
                
                </SecondImageColumn>

            </SecondColumn>
            </SecondWrapper>
        </Wrapper>
    )

}
export default Arts;
