import styled, { css } from 'styled-components';

type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

type ButtonProps = {
  variant: ButtonVariants;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonVariantDetails = {
  backgroundColor: string;
  width: string;
  height: string;
  borderRadius: string;
  borderWidth:string;
  borderColor:string;
  font: string;
  color: string;
  margin: string;
};

const variantDetails: Record<ButtonVariants, ButtonVariantDetails> = {
  primary: {
    backgroundColor:'#c2abe8',
    color: 'white',
    width: 'auto',
    height:'auto',
    borderRadius: '35px',
    borderWidth: '1px',
    borderColor: '#c2abe8',
    font: 'bold normal normal 18px/24px MikelaRegular',
    margin: '0px 1.5vw 0px 1.5vw',
  },
  //the following is the circular button
  secondary: {
    backgroundColor: 'white',
    color: '#480CA8',
    width: '120px',
    height:'120px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: '#480CA8',
    font: 'normal normal normal 20px/24px Montserrat',
    margin: '0px 30px 50px 0px'
  },
  //tertiary
  tertiary: {
    backgroundColor: 'white',
    color: '#480CA8',
    width: '120px',
    height:'120px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: '#480CA8',
    font: 'normal normal normal 20px/24px Montserrat',
    margin: '0px 30px 50px 0px'
  },
  quaternary:{
    //same as primary but opposite in color
    backgroundColor:'white',
    color: '#c2abe8',
    width: 'auto',
    height:'auto',
    borderRadius: '35px',
    borderWidth: '1px',
    borderColor: '#c2abe8',
    font: 'bold normal normal 18px/24px Montserrat',
    margin: '10px 0 0 0'

  }
};

const StyledButton = styled.button<ButtonProps>`
  ${props => {
    const { backgroundColor, color, width, height, borderRadius, borderWidth, borderColor,font, margin} = variantDetails[props.variant];
    return css`
      background-color: ${backgroundColor};
      color: ${color};
      width: ${width};
      height: ${height};
      border-radius: ${borderRadius};
      border-width: ${borderWidth};
      border-color: ${borderColor};
      font: ${font};
      margin: ${margin};
    
    `;
  }}
  /* this is where styles are added for all the buttons*/
  text-align:center;
  cursor: pointer;
  padding:5px 10px;
`;

export const Button: React.FC<ButtonProps> = ({ variant, children, ...props }) => (
  <StyledButton variant={variant} {...props}>
    {children}
  </StyledButton>
);
