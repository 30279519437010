import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AuthContextType {
    token: string | null;
    setToken: (token: string | null) => void;
  }
  
const AuthContext = createContext<AuthContextType>({ token: null, setToken: token => console.warn('no token provider') });
export const useAuth = () => useContext(AuthContext);

export function AuthProvider ({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<string | null>(null);

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

  