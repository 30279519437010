import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import { API_URL } from '../../urls';
import {useUser} from '../../Hooks/Context/UserContext';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: 2,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const ButtonStyled = styled(Button)`
  && {
    background-color: #480CA8;
    color: white;
    &:hover {
      background-color: #4a2ea5;  // Adjusted hover color
      
    }
  }
`;


export default function ChangePasswordModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newPassword, setNewPassword] = React.useState('');
  const {token} = useUser();


  const handleChangePassword = async () => {

    try {
      const response = await fetch(API_URL+'/change_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ newPassword }),
      });
      

      if (response.ok) {
        console.log('Password changed successfully');
        handleClose();
      } else {
        console.error('Failed to change password');
      }
    } catch (error) {
      console.error('Error occurred while changing password', error);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>Change Password</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
        sx={style}
        alignItems="center"
        display="flex"
        flexDirection='column'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Password
          </Typography>
          <br/>
          <TextField
            id="outlined-basic"
            label="New Password"
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <br/>
          <ButtonStyled onClick={handleChangePassword}> Change Password </ButtonStyled>
        </Box>
      </Modal>
    </div>
  );
}