import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useCreationProcessContextValue } from '../../../../Hooks/Context/CreationProcessContext';
import { Button } from '@mui/material';
import TransgenderIcon from '@mui/icons-material/Transgender';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
    position: absolute;
    width: 200px;
    left: 20%;
    top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-family: 'MikelaRegular';
    @media(max-width: 1100px){
        top: 13%;
    }
    @media(max-width: 800px){
        position: relative;
        width: 100%;
        left: unset;
        top: unset;
        margin-bottom: 20px;
    }
`;

const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    @media(max-width: 800px){
        flex-direction: row;
    }
`;

const GenderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align:center;
    @media(max-width: 800px){
        flex-direction: row;
        margin-right: 40px;
    }
`

const femaleSymbolSVG = `url('data:image/svg+xml;utf8,${encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 15 20"><text x="0" y="16" font-family="Arial" font-size="20" fill="white">&#9792;</text></svg>')}')`;
const maleSymbolSVG = `url('data:image/svg+xml;utf8,${encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 15 20"><text x="0" y="16" font-family="Arial" font-size="20" fill="black">&#9794;</text></svg>')}')`;

const GenderSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#7B1FA2',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: femaleSymbolSVG,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#7B1FA2',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: maleSymbolSVG,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        borderRadius: 20 / 2,
        backgroundColor: 'black',
    },
}));
const StyledButton = styled(Button)`
    border-radius: 50% !important;
    width: 10px;
    aspect-ratio: 1/1;
    background-color: #7b1fa2 !important;
    color: white !important;
    @media(max-width: 800px){
        margin-left: 20px !important;
    }
`;

const FiltersToggle = styled.button`
    background-color: unset;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: -10px;
`;

const ExtraSettingsWidget = () => {
    const { setAge, setGender, gender } = useCreationProcessContextValue();
    const [genderChecked, setGenderChecked] = useState(true);
    const [age, setAgeValue] = useState('');
    const [hasSelected, setHasSelected] = useState(false);
    const [areFiltersVisible, setareFiltersVisible] = useState(false);

    const handleGenderChange = () => {
        setGenderChecked(!genderChecked);
        setGender(genderChecked ? 'm' : 'f');
    };

    const handleAgeChange = (event: any) => {
        setAgeValue(event.target.value);
        setAge(event.target.value);
    };

    useEffect(()=>{
        handleGenderChange();
    },[hasSelected]);

    const toggleFilters = () => {
        setareFiltersVisible(!areFiltersVisible);
    };



    const Filters = () =>{
        return(
        <>
            <p>For better results set the following prompts:</p>
                
            <InformationWrapper>
                <GenderWrapper>
                <Typography>Choose Gender:</Typography>
                {(hasSelected)?(
                                <GenderSwitch
                                checked={genderChecked}
                                onChange={handleGenderChange}
                                inputProps={{ 'aria-label': 'gender switch' }}
                                sx={{ m: 1 }}
                            />
                ):(<StyledButton onClick = {()=>setHasSelected(true)}><TransgenderIcon /></StyledButton>)}
                <br/>
                </GenderWrapper>
                <Typography>Age:</Typography>
                <br/>
                <TextField
                    id="age-field"
                    label=""
                    variant="outlined"
                    value={age}
                    onChange={handleAgeChange}
                    style={{ width: '70px' }} 
                />
            </InformationWrapper>
            </>
                )

            }

    return (
        <Wrapper>
            {(isMobile)?(
            <>
                {(areFiltersVisible)?(
                    <>
                    <FiltersToggle onClick={toggleFilters}>Show less</FiltersToggle>
                        {Filters()}
                    </>
                ):(
                    <>
                    <FiltersToggle onClick={toggleFilters}>Show filters</FiltersToggle>
                    </>
                )}
            </>
            ):(
                <>
                    {Filters()}
                </>
            )}
            
        </Wrapper>
    );
};

export default ExtraSettingsWidget;
