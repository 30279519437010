import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import StyleOf19120 from '../../assets/FashionImages/1920sMan.jpg';
import DiorFemale from '../../assets/FashionImages/Dior-female.jpg';
import {NavigationLink} from '../NavigationLink';

const Wrapper = styled.div`
    height: 100vh;
    width:100vw;
    background-color:black;    
    color:#FFFFFF;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center; 
    text-align:center;
    overflow: hidden;
    @media (max-width: 800px) {
        flex-direction: column;
        color:black;
        height: calc(100vh - 30px);
    }
`;
const FirstColumn = styled.div`
    width:50%;
    height:100%;
    background-color:white;
    position: relative;
    @media(max-width: 800px){
        position: absolute;
        width: 100%;
    }
`;
const SecondColumn = styled.div`
    width:50%;
    padding:20px;

    @media(max-width: 800px){
        position: relative;
        margin-top: 40px;
        background-color: rgb(211, 211, 211, 0.7);
        border-radius: 20px;
        z-index:1;
    }
`;

const MainImage = styled.img`
    width:100%;
    height:inherit;
    object-fit:cover;
`;

const Header = styled.h1`
    font-size: 50px;
    font-family: 'MikelaRegular';
    @media(max-width: 800px){
        font-size: 40px;
    }
`;

const Text = styled.p`
    font-size: 20px;
`;
const StyledNavigation = styled(NavigationLink)`
    border-color:#E2B659;
    color:white;
    font-family: 'MikelaRegular';
    background-color: #E2B659;
    padding: 15px;
    &:hover{
        color: black;
        border-color:black;
    }

    @media(max-width: 800px){
        border-color:white;
        padding: 10px;
        font-size: 20px;
    }
`;


const Fashion = () =>{
    const [mainImage, setMainImage] = useState<string>(StyleOf19120);

    useEffect(() => {
      const intervalId: NodeJS.Timeout = setInterval(() => {
        // Call toggleImage function every 2 seconds
        toggleImage();
      }, 3000);
  
      // Cleanup the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }, []);
  
    const toggleImage = (): void => {
      setMainImage((prevImage) => (prevImage === StyleOf19120 ? DiorFemale : StyleOf19120));
    };

    return(
        <Wrapper>
        <SecondColumn>
            <Header>
                Imagine it, make it, post it
            </Header>
            <Text>Add your image.</Text>
            <Text>Choose your Effect.</Text>
            <Text>Become runway ready! Slay Queen!!(and kings)</Text>
            <br/>
            <StyledNavigation variant='primary' to='create'>Discover</StyledNavigation>

        </SecondColumn>
            <FirstColumn>
                <MainImage src={mainImage} onClick={toggleImage}/>
            </FirstColumn>
        </Wrapper>
    )

}
export default Fashion;
