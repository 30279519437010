import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import en from './locales/en.json';
import ar from './locales/ar.json';
import fr from './locales/fr.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
        en: { translation: en },
        fr: { translation: fr },
        ar: { translation: ar },
      },
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
