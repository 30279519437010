import { useState } from 'react';

interface FetchState<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
}

const useFetch = <T,>(url: string, method: string = 'GET', bodyData: any = null) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState<Error | null>(null);

  const [state, setState] = useState<FetchState<T>>({
    data: null,
    loading: false,
    error: null,
  });

  const fetchData = async () => {
    try {
      setState({ ...state, loading: true });
      const options: RequestInit = { method };
      if (bodyData) {
        options.headers = { 'Content-Type': 'application/json' };
        options.body = JSON.stringify(bodyData);
      }
  
      const res = await fetch(url, options);
      const json = await res.json() as T;
  
      setState({ ...state, data: json, loading: false });
      return json; 
    } catch (error) {
      setState({ ...state, error: error as Error, loading: false });
      throw error; 
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};


export default useFetch;
