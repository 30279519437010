
import React from 'react';
import styled from 'styled-components';


import SuperWoman from '../../assets/FictionImages/superwomanWebsite.png';
import {NavigationLink} from '../NavigationLink';
import HarryPotterWebsite from '../../assets/FictionImages/HarryPotterWebsite.png';
import ElsaSwapped from '../../assets/FictionImages/ElsaSwapped.png';

import YoungSkylwaker from '../../assets/FictionImages/YoungSkylwaker.png';


const imageWidth = 15;
const imageWidthMobile = 50;


const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    text-align:center;
    width: 100%;
    height: 100vh;
    background-color: #495170;
    color: #C1FAFF;
    @media (max-width: 800px) {
        flex-direction: column;
        padding-bottom: 10px;
    }
`;


const RightColumn = styled.div`
    width:50%;
    height:50vh;
    @media( max-width: 800px){
        width:20vw;
        height:50vh;
        padding-top: 0px;
    }
`;
const LeftColumn = styled.div`
    width:50%;
    @media( max-width: 800px){
        width:100%;
        height:50vh;
    }
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const ImageContainer = styled.img`
    border-radius: 12%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    object-fit: cover;
`;
const MainImage = styled(ImageContainer)`
    position:absolute;
    z-index:1;
    width: ${imageWidth}vw;
    height:50vh;
    background-color: #854D64;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    @media( max-width: 800px){
        width:${imageWidthMobile}vw ;
        height:40vh;
        margin-left:-25vw;
        margin-top:5vh;
    }
`;
const OtherImages = styled(ImageContainer)`
    z-index: 0;
    position:absolute;
`;
const TopRight = styled(OtherImages)`
    margin-left:${imageWidth-3}vw;
    margin-top:-12vh;
    width: 10vw;
    height:13vw;
    z-index:3;
    background-color: #825F7F;
    @media( max-width: 800px){
        margin-left:14.5vw ;
        margin-top:14vw;
        width: 30vw;
        height:30vw;
    }
`;
const BottomRight = styled(OtherImages)`
    margin-left:${imageWidth-5}vw;
    margin-top:30vh;
    z-index:1;
    width: 10vw;
    height:15vw;
    background-color:#7B7193;
    @media( max-width: 800px){
        margin-left:5vw ;
        width: 30vw;
        height:30vw;
        margin-top:34vh;
    }
`;
const MiddleLeft = styled(OtherImages)`
    margin-top:4vh;
    margin-left:-9.5vw;
    width: 11vw;
    height:15vw;
    background-color:rgba(117, 130, 160);
    z-index:1;
    @media( max-width: 800px){
        margin-left:-44vw ;
        width: 30vw;
        height:40vw;
        margin-top:24vh;
    }

`;
const Header = styled.h1`
    font-size: 50px;
    font-family: 'MikelaRegular';
    @media( max-width: 800px){
        font-size: 30px;
    }
`;
const Content = styled.span`
    font-size:1.563rem;
    @media( max-width: 800px){
        font-size:1rem;
    }
`;
const Characters = styled.span`
    font-size:1.563rem;
    @media( max-width: 800px){
        font-size:1rem;
        margin-bottom: 3px;
    }
`;
const Styled_Navigation_link = styled(NavigationLink)`
    border-color:#E6F4F0;
    background-color:#C1FAFF;
    color: #82A0AA;
    font-size: 1.4rem;
    width: 40%;
    align-self: center;
    &:hover{
        color: grey;
    }
    @media(max-width: 800px){
        width: 40%;
        align-self: center;
    }
`;
const LeftColumntContentWrapper = styled.div`
    position: absolute;
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media(max-width: 800px){
        width: 100%;
    }
`
const Fiction = () =>{

    return(
        <Wrapper>
            <RightColumn>
                <TopRight   src={YoungSkylwaker}/>
                <MainImage src={HarryPotterWebsite}/>
                <BottomRight src={SuperWoman}/>
                <MiddleLeft  src={ElsaSwapped}/>
            </RightColumn>

            <LeftColumn>

            <LeftColumntContentWrapper>
                    <Header>Become any character</Header>
                    <Content>What do you want to become next?</Content>
                    <br/>
                    <Characters>Harry Potter? </Characters>
                    <Characters>Superwoman?  </Characters>
                    <Characters>Elsa?</Characters>
                    <Content>We have them all!!</Content>
                    <br/>
                    <br/>
                    <Styled_Navigation_link variant='primary' to='./create'>TRY NOW!!!</Styled_Navigation_link>
            </LeftColumntContentWrapper>
            </LeftColumn>
        </Wrapper>
    )

}
export default Fiction;