import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {LuImagePlus} from 'react-icons/lu';
import CircularProgress from '@mui/material/CircularProgress';

import {useCreationProcessContextValue} from '../../../../Hooks/Context/CreationProcessContext';
import {useUser} from '../../../../Hooks/Context/UserContext';
import {API_URL} from '../../../../urls';


interface FileSectionProps {
    onFileSelect: (file: File | null) => void;
    onFileDeselected: () => void;
    file: any;
    setStep: (number: number) => void;
}
const FileColumn = styled.div`
    flex: 1;
    margin-left: 10px; 
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
`;

const Image = styled.img`
    width:20%;
    height:40vh;
    border-radius:15px;
    cursor:pointer;
    @media(max-width: 800px){
        width: 80%;
        display: none;
    }
`;
const ChooseFileInput = styled.input`
  display: none;
`;


const Dropzone = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    display:flex;
    flex-direction:row;
    align-items:center;
    width:60%;
    @media(max-width: 800px){
        width:100%;
    }
`;

const CustomButton = styled.button`
    margin-top:20px;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    color:  rgb(72, 12, 168, 0.7);
    border: 2px dashed rgb(72, 12, 168, 0.7);
    font-size:25px;
    width:50%;
    height:35vh;

    @media(max-width: 800px){
        width:80%;
    }
`;
const AddImageIcon = styled(LuImagePlus)`
    width:20%;
    height:10%;
`;
const AddImageText = styled.h2`
    font-family:MikelaRegular;
`;
const ImageContainer = styled.div`
    position:absolute;
    width:20%;
    height:40vh;
    border-radius:15px;
    border: 2px solid #bda6e0;
    cursor:pointer;
    display: flex;
    text-align:center;
    flex-direction:column;
    justify-content:center;
    color: rgba(255,255,255,0);
    @media(max-width: 800px){
        position:relative;
        color: black;
        width:90%;
        height:10vh;
    }
    &:hover{
        background-color:rgb(128,128,128,0.4);
        font-size: 18px;
        color:white;
    }
`;

const LoadinOverlay = styled.div`
    background-color: rgb(128,128,128,0.4);
    border-radius:15px;
    position: absolute;
    z-index: 3;
    width:20%;
    height:40vh;
    display: flex;
    justify-content:center;
    text-align:center;
    align-items: center;
    @media(max-width: 800px){
        width: 80%;
        height:10vh;
    }
`

function validURL(str: string) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?'+ // port
    '(\\/[-a-z\\d%@_.~+&:]*)*'+ // path
    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}



const FileSection: React.FC<FileSectionProps> = ({ onFileSelect, onFileDeselected, file,setStep }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState<string | null | any>(file);
    const [error, setError] = useState(false);
    const { token } = useUser();
    const {effectName,  selectedImage, addImage, selectedEffectId, completedCreation, imageId, loading } = useCreationProcessContextValue();


    useEffect(() => {
        setPreview(selectedImage);
        setStep(3);

      }, [selectedImage]);


    useEffect(() => {
        if (file instanceof Blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else if (typeof file === "string" && validURL(file)) { // If file is a string and it's a URL
            setPreview(file);
        }
    }, [file]);

    const handleClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      }
      const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        if (file) {
            if (file.type.startsWith('image/')) {
                setError(false);
                const reader = new FileReader();
                onFileSelect(file);
                reader.onloadend = () => {
                    setPreview(reader.result as string);
                };
                reader.readAsDataURL(file);
            } else {
                setError(true);
            }
        }
    };


    const handleSelectURL = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (validURL(e.target.value)) {
            setError(false);
            setPreview(e.target.value);
        } else {
            setError(true);
        }
    };

    const handleImageSelect = (imageUrl: string) => {
        setPreview(imageUrl);
        fetch(imageUrl)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], "filename", { type: "image/jpeg" });
            onFileSelect(file);
          });
    };

    const onDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    
        if (!e.dataTransfer.items || e.dataTransfer.items.length === 0) {
          return;
        }
    
        const fileItem = e.dataTransfer.items[0];
    
        if (fileItem.kind === 'file') {
          const droppedFile = fileItem.getAsFile();
    
          if (droppedFile) {
            onFileSelect(droppedFile);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(droppedFile);
          }
        }
      }, [onFileSelect]);
    
      const preventDefault = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();
    
    return (
        
        <FileColumn>
            
            <ChooseFileInput 
            type="file" 
            ref={fileInputRef}
            onChange={handleSelectFile} 
            accept="image/*"
            />
            {(preview)?(
            <>
                {(loading)?(
                <LoadinOverlay>
                    <CircularProgress color="secondary" />
                </LoadinOverlay>):(
                    <>
                    </>
                )}
                <ImageContainer onClick={handleClick}>Upload an other Image</ImageContainer>
                <Image src={preview} alt="Selected" onClick={handleClick}/>
            </>
                ):(<>
            <Dropzone onDragOver={preventDefault} onDragLeave={preventDefault} onDrop={onDrop}>

                <CustomButton onClick={handleClick} >
                    <AddImageIcon/>
                    <AddImageText>Add an Image</AddImageText>
                </CustomButton>
            </Dropzone>
            
            
            </>)}

        </FileColumn>
    );
};

export default FileSection;
