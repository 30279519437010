// ImageOverlay.tsx
import React from 'react';
import styled from 'styled-components';
import Carousel from './Carousel';
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";




interface ImageOverlayProps {
  src: string | null;
  onClose: () => void;
  previousClick: () => void;
  nextClick: () => void;
}

const OverlayWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); // semi-transparent black background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;
const Image = styled.img`
  width: 50vw;
  height: 50vw;
  @media(max-width: 800px){
    width: 70vw;
    height: 70vw;
  }
`;
const CloseButton = styled.button`
  border-radius: 50%;
  background-color: unset;
  color: white;
  border: 0px;
  font-size: 50px;
  position: absolute;
  right: 10vw;
  top: 20px;
  cursor: pointer;
  @media(max-width:800px){
    right: 5vw;
  }
`;
const BackButtonStyled = styled(MdArrowBackIos)`
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

const ForwardButtonStyled = styled(MdArrowForwardIos)`
  height: 40px;
  width: 40px;
  cursor: pointer;
`;
const CustomText = styled.p`
  font-size: 2rem;
  color: white;
`;

const ImageOverlay: React.FC<ImageOverlayProps> = ({ src, onClose, previousClick, nextClick }) => {
  return (
    <OverlayWrapper>
        <CloseButton onClick={onClose}>X</CloseButton>
        <BackButtonStyled color='white' onClick={previousClick}>previous</BackButtonStyled>
        {src ? (
        <Image src={src} />
      ) : (
        <CustomText>The Image is generating</CustomText>
      )}
        <ForwardButtonStyled color='white' onClick={nextClick}>next</ForwardButtonStyled>
    </OverlayWrapper>
  );
};

export default ImageOverlay;
