import styled from 'styled-components';


export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 30px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionLayout = styled.div`
  display:flex;
  flex-direction: column;
  height:200px;
  overflow-y: auto;
`;

export const StyledValue= styled.span`
  margin-top:20px;
`;

export const NameOfComponent = styled.span`
  font-size:20px;
  height:30px;
  border-bottom:1px black solid;
  margin-bottom: 20px;
`;