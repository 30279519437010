import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {API_URL} from '../../urls';


const Wrapper = styled.div`
    width: 100%;
`;
const StyledAlert = styled(Alert)`
    text-align:center;
`;

const VerificationPage = () =>{
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        fetch(`${API_URL}/verify_email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setLoading(false);
            console.log(data.message); // Log the response message
            // Add any additional logic based on the response from the backend
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle the error as needed
          });

        console.log(token)
      }, [token]);

      // need to add: 1) verification successful and then take them to the create page 2) verification unussccesful and a button that tells them to try again. 

    return(
        <Wrapper>
            <StyledAlert variant='filled' severity="success">Email verified successfully</StyledAlert>
        </Wrapper>
    )

}
export default VerificationPage;