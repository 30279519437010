import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineUser } from 'react-icons/ai'; 
import { FaCircleUser } from "react-icons/fa6";
import { useUser } from '../../Hooks/Context/UserContext';
import styled from 'styled-components';
import {NavigationLink} from '../NavigationLink';
import {useAuth} from '../../Hooks/Context/AuthenticationContext';

type DropdownContentProps = {
  isOpen: string;
}


const Wrapper = styled.div`

  cursor: pointer;
  width:30px;
  height:30px;
  color:#480ca8;
  z-index:1000;
  @media(max-width: 700px){
    right: 10vw;
    margin-right: 20px;
  }
`;

const DropDownContent = styled.div<DropdownContentProps>`
  display: ${props => props.isOpen === 'true'? 'block' : 'none'};;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1001;
  cursor: pointer;
  background-color:white;
  border-radius:10px;
  border-color:#480CA8;
  border-style: solid;
  border-width:1px;
  right:12%;
  margin-top: 10px;
  @media(max-width: 700px){
    right:18%;
  }
`;

const StyledNavigationLink = styled(NavigationLink)`
  text-decoration: none;
  display: block;
  padding:4px!important;
`;

const UserCircle: React.FC = () => {
  const { setUser,setToken } = useUser();
  const [isDropDownOpen, setIsDropDownOpen]  = useState('false');

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        // Clicked outside the UserCircle component
        setIsDropDownOpen('false');
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUserIconClick = () => {
    console.log(isDropDownOpen);
    if(isDropDownOpen === 'true'){
      setIsDropDownOpen('false')
    }else{
      setIsDropDownOpen('true')
    }
  };

  const handleLogout = () => {
    console.log('logout clicked')
    setToken(''); 
    setUser(null);
  };

  return (
    <Wrapper onClick={handleUserIconClick} ref={wrapperRef} >
      <FaCircleUser size={35} /> 
      <DropDownContent isOpen={isDropDownOpen}>

        <StyledNavigationLink variant="navigation" to='/Account'>Account Settings</StyledNavigationLink>
        
        <StyledNavigationLink variant="navigation" to='/gallery'>My Images</StyledNavigationLink>
        
        <StyledNavigationLink variant="navigation" onClick={handleLogout}to='/'>Log Out</StyledNavigationLink>
        
      </DropDownContent>
    </Wrapper>
  );
};

export default UserCircle;
