import React, { useEffect, useState } from "react";
import { API_URL } from "../../urls";
import { useUser } from "../../Hooks/Context/UserContext";

const StripePricingTable = () => {
    const {token } = useUser();
    const [user_id, set_user_id] = useState('');

      // useEffect for fetching effects data
    useEffect(() => {
        fetch(API_URL + `/authenticate_token?token=${token}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // Add the token to the Authorization header
              'Authorization': `Bearer ${token}`,
            },
          })
        .then(response => response.json())
        .then(data => {
            set_user_id(data.user_id);
        })
        .catch(error => console.error('Error:', error));
    }, []);


    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
        document.body.removeChild(script);
        };
        
    }, []);
    return React.createElement("stripe-pricing-table", 
    {
        "pricing-table-id": 'prctbl_1OXoihAC5xIxVIOpV5xQytp4',
        "publishable-key": 'pk_live_51ODu2uAC5xIxVIOp2wRz4Bz57fIMlLTw4YNfbNdOEhUfwsU90oPCAEd8YhuGWmkkBmK6KoFji3s3I0jZRQqCKC7Q00H5uZQZQk',
        "client-reference-id": user_id
    });
};
export default StripePricingTable;