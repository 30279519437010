import React, {useState,useMemo} from 'react';
import styled from 'styled-components';
import {useCreationProcessContextValue} from '../../../Hooks/Context/CreationProcessContext';
import {Button} from '../../Button';
import { useNavigate } from 'react-router-dom';
import InProgressImage from '../../Gallery/GalleryComponents/InProgressImage';


interface FinishedProps {
    image_id: string | null;

} 
const Wrapper = styled.div`
    margin:50px 25%;
    padding:20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #c2abe8;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #7B1FA2;
    color: white;
    @media( max-width: 800px){
        padding: 10px;
        margin:50px;
        flex-direction: column;
        justify-content: center;
    }
`;
const InformationWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
`;


const StyledButton = styled.button`
    border-radius: 20px;
    background-color: #7B1FA2;
    border: 1px solid #c2abe8;
    font-size: 20px;
    color:white;
    cursor: pointer;
    padding: 7px;
    font-family: 'MikelaRegular';
`;


const Finished = () =>{    
    const {  selectedImage , selectedEffectId, addEffect, imageId, effectName } = useCreationProcessContextValue();
    const [updateTrigger, setUpdateTrigger] = useState<number>(0);

    let navigate = useNavigate();
    console.log(imageId);
    const handleCreateNew = () =>{
        addEffect('');
        navigate('/create');
    };
    const handleVisitEffects = () =>{
        navigate('/ourEffects');
        addEffect('');
    };

  const triggerUpdate = () => {
    setUpdateTrigger(prev => prev + 1);
    navigate('/gallery');
  };

  const getOriginalImage = () =>{
    if(selectedImage != null){
        return selectedImage;
    }
    return ''
  }
    return(
        <Wrapper>
            <InProgressImage triggerUpdate={triggerUpdate} original_image={getOriginalImage()} image_id={imageId} effect_used={selectedEffectId}/>
            <InformationWrapper>
                <div>
                    <h3>Meanwhile go ahead and try other effects</h3>
                    <StyledButton onClick={handleCreateNew} >Create more</StyledButton>
                </div>
            </InformationWrapper>

        </Wrapper>

    );

} ;
export default Finished;