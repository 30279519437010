import React from 'react';
import styled from 'styled-components';
import { useUser } from '../../Hooks/Context/UserContext';
import LogInPageBackground from './LogInPageBackground';
import LoginForm from './LoginForm';

const Wrapper = styled.div`
    height:96vh;
    width:100%;
    background-color: rgba(0,0,0,0.1);
    display:flex;
    justify-content:center;
    text-align:center;
    align-items: center;
    position:relative;
    z-index:10;
    overflow:hidden;

`;

const LogInCard = styled.div`
  width:30%;
  height:90%;
  background-color:rgba(255,255,255,0.9);
  border-radius: 2%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  text-align:center;
  align-items: center;
  padding: 20px;
  max-width: 400px;
  @media( min-width: 1200px){
    height: 60%;
  }
  @media( max-width: 700px){
    width:70%;
    height:70%;
  }
`;


const LogInPage = () =>{
  const {token} = useUser();
  
    
    return(
        <LogInPageBackground>
            <Wrapper  id='loginPage'>
              {(token === null || token === '')?(
                <LogInCard>
                  <LoginForm navigateTo='/create'/>
                </LogInCard>
              ):(
                <div></div>
              )
              }

          </Wrapper>

        </LogInPageBackground>
    )
}
export default LogInPage;