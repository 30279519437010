import './App.css';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import WebsiteRouter from './components/Router/WebsiteRouter';
import {BrowserRouter as Router} from 'react-router-dom';
import NavigationBar from './components/NavigationBar/NavigationBar'
import './components/i18n/i18n';
import { UserProvider, useUser } from './Hooks/Context/UserContext';
import {PopupProvider} from './Hooks/Context/PopUpContext';
import { AuthProvider } from './Hooks/Context/AuthenticationContext';
import {CurrentEffectGroupProvider} from './Hooks/Context/CurrentEffectGroupContext';
import {CreationProcessProvider} from './Hooks/Context/CreationProcessContext';
import { EffectsDataContextProvider, useEffectsDataContext } from './Hooks/Context/EffectsDataContext';
import CircularProgress from '@mui/material/CircularProgress';
import {API_URL} from './urls';
import Footer from './components/Footer/Footer';

//publishable key:pk_live_51ODu2uAC5xIxVIOp2wRz4Bz57fIMlLTw4YNfbNdOEhUfwsU90oPCAEd8YhuGWmkkBmK6KoFji3s3I0jZRQqCKC7Q00H5uZQZQk

const theme = {
  primaryColor: '#480CA8',
  secondaryColor: 'white',
  // Add other global style variables as needed
};
const AppWrapper = styled.div`
  font-family:bebas neue;
  overflow-x: hidden;

`;
const Pages = styled.div`
  min-height:calc(100vh - 51px);
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self:center;
  margin:45vh 45vw;
  @media(max-width: 800px){
    margin: 45vh 40vw;
  }
`;

const Content = () => {
  const { setEffectsFromArray } = useEffectsDataContext();
  const { setUser, setToken, token } = useUser();
  const [ loading, setLoading] = useState(true);
  const [effectsArray, setEffectsArray] = useState([])


  // useEffect for fetching effects data
  useEffect(() => {
    fetch(API_URL + '/effects')
      .then(response => response.json())
      .then(data => {
        setEffectsArray(data)
        setEffectsFromArray(data);
        setLoading(false);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  


  const extractExpiryDate = (token: string) => {
    // Split the token into its three parts
    const [header, payload, signature] = token.split('.');
  
    // Decode the base64-encoded payload
    const decodedPayload = JSON.parse(atob(payload));
  
    // Extract the expiry date from the payload
    const expiryDate = new Date(decodedPayload.exp * 1000); // Convert to milliseconds
  
    return expiryDate;
  };
  const deleteToken = () =>{
    localStorage.removeItem('generate-me-user');
    localStorage.removeItem('generate-me-token');
    setUser(null);
    setToken('');
  }


  // useEffect for retrieving user information
  useEffect(() => {
    setLoading(true);
    const storedUser = localStorage.getItem('generate-me-user');
    const storedToken = localStorage.getItem('generate-me-token');

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    if (storedToken) {

      setToken(storedToken);
      const expiryDate = extractExpiryDate(storedToken);
      const currentDate = new Date();
      if (currentDate > expiryDate) {

        console.log('expired ', storedToken);
        // Token has expired, clear user and token from local storage
        deleteToken();
        return; // Stop further execution
      } else{

        fetch(API_URL + `/authenticate_token?token=${storedToken}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Add the token to the Authorization header
            'Authorization': `Bearer ${storedToken}`,
          },
        })
        .then(response => {
          setLoading(false);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          setLoading(false);
          // Handle the successful response data here
        })
        .catch(error => {
          setLoading(false);
          // Handle errors here
          deleteToken();
          console.error('Error:', error.message);
        });
      }
    }
  }, [setUser, setToken]);

  if (loading) {
    return <StyledCircularProgress size={100} disableShrink color="secondary" />
  }

  return (
    <AppWrapper>
      <Router>
        <AuthProvider>
            <PopupProvider>
              <CreationProcessProvider>
                <CurrentEffectGroupProvider>
                  <ThemeProvider theme={theme}>
                      <NavigationBar />
                      <Pages>
                        <WebsiteRouter />
                      </Pages>
                      <Footer />
                  </ThemeProvider>
                </CurrentEffectGroupProvider>
              </CreationProcessProvider>
            </PopupProvider>
        </AuthProvider>
      </Router>
    </AppWrapper>
  );
};
function App() {

  return (
    <UserProvider>
      <EffectsDataContextProvider>
        <Content />
      </EffectsDataContextProvider>
    </UserProvider>
  );
}

export default App;
