
import React, { createContext, useState, useContext, ReactNode } from 'react';

type Effect = {
    effect_id: string;
    effect_name: string;
    effect_image_url: string;
    effect_category: string;
    quantity_of_use: number;
    tier: string;
    
}
type Category = {
  categoryName: string;
  effects: Effect[];
}

interface EffectsDataContextProviderProps {
    children: ReactNode;
}

interface EffectsDataContextValue {
    categories: Category[];
    setCategories: (c: Category[]) => void;
    effects: Effect[];
    setEffects: (e: Effect[]) => void;
    getCategoryByIndex: (index: number) => Category;
    getCategoryByName: (categoryName: string) => Category | undefined;
    setEffectsFromArray: (effects: Effect[]) => void;
    getEffectNameById: (effectId: string) => string | undefined;
}


const EffectsDataContext = createContext<EffectsDataContextValue | undefined>(undefined);


// EffectsDataContextProvider component
const EffectsDataContextProvider: React.FC<EffectsDataContextProviderProps> = ({ children }) => {

  const [categories, setCategories] = useState<Category[]>([]);
  const [effects, setEffects] = useState<Effect[]>([]);
  
  const getCategoryByName = (categoryName: string) => {
    var effectsForCategory = categories.find(e => e.categoryName === categoryName)

    return effectsForCategory;
  }
  const getCategoryByIndex = (index: number) =>{
    return categories[index];
  }



  const setEffectsFromArray = (e: Effect[]) => {
    // sets the effects useState from the e array
    let tempEffects : Effect[] = [];
    e.forEach(effect => {

      tempEffects.push(
        {
          effect_id: effect.effect_id,
          effect_name: effect.effect_name,
          effect_image_url: effect.effect_image_url,
          effect_category: effect.effect_category,
          quantity_of_use: effect.quantity_of_use,
          tier: effect.tier
        }
      )
    });
    setEffects(tempEffects);

    let c : Category[] = [];
    tempEffects.forEach((effect) => {
      const categoryIndex = c.findIndex((category) => category.categoryName === effect.effect_category);
      
      if (categoryIndex !== -1) {
          c[categoryIndex].effects.push(effect);
      } else {
          c.push({ categoryName: effect.effect_category, effects: [effect] });
      }
  });
  setCategories(c);

  };

  const getEffectNameById = (effectId: string): string | undefined => {
    const effect = effects.find((effect) => effect.effect_id === effectId);
    return effect ? effect.effect_name : undefined;
  };


  const value = { categories, setCategories, effects: effects, getCategoryByName, getCategoryByIndex, setEffects, setEffectsFromArray, getEffectNameById };

  return (
      <EffectsDataContext.Provider value={value}>
          {children}
      </EffectsDataContext.Provider>
  );
};
  
const useEffectsDataContext = (): EffectsDataContextValue => {
    const context = useContext(EffectsDataContext);
    
    if (context === undefined) {
      throw new Error('useEffectsDataContext must be used within a EffectsDataContextProvider');
    }
    
    return context;
  };
  
export { EffectsDataContextProvider, useEffectsDataContext };
