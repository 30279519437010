import React from 'react';
import styled from 'styled-components';
import { useUser } from '../../Hooks/Context/UserContext';
import UserCircle from './UserCircle';
import {useAuth} from '../../Hooks/Context/AuthenticationContext';
import {Button} from '../Button';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';



const Wrapper = styled.div`

  @media(max-width:700px){
    position: absolute;
    right: 30px;
    margin-right: 40px;
  }
`;
const HashLinkStyled = styled(HashLink)`
  color: inherit;
  font-family: inherit;
  text-decoration: none;
`;
const StyledButton = styled(Button)`
  position: relative;
  right: -20px;
  @media(max-width: 800px){
    right: -40px;
  }
`;

const LogInOrAccount = () =>{

  const { token } = useUser();

    return(
        <Wrapper>
        {token ? <UserCircle/> : 
          <StyledButton variant='primary'>
            <HashLinkStyled to={"/#loginPage"}>Log In</HashLinkStyled>
          </StyledButton>}
        </Wrapper>

    )

};
export default LogInOrAccount;