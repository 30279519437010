import React, { useState, useEffect, ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { useUser, User } from '../../Hooks/Context/UserContext';
import useFetch from '../../Hooks/useFetch';
import Google from './google';
import {useNavigate} from "react-router-dom";
import {API_URL} from '../../urls';

interface LoginFormProps {
  navigateTo: string;

}

interface LoginResponse {
    success: boolean;
    user: User;
    token: string;
    message: string;
    user_id: string;
  }

  interface CreateAccountResponse {
    success: boolean;
    user: User;
  }



const Input = styled.input`
  display: block;
  margin-bottom: 20px;
  width:99%;
  border-width: 0px 0px 1px 0px;
  height:45px;
  border-radius:5px;
  &:focus {
    border-width: 0px 0px 1px 0px; 
    outline: none; 
  }
  &::placeholder {
    text-align: center;
  }

`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ButtonsWrapper = styled.div`
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-between;
  width:80%;
`;
const GoogleDivStyled = styled.div`
  display:flex;
  align-items: center;
  width:100%;
  flex-direction: column;
`;
const ButtonStyled = styled(Button)`
  width:100%;
   a {
    cursor: pointer;
  }
`;

const Header = styled.h2`
font-family: MikelaRegular;
  font-size:1.8rem;
  height:2.25 rem;
  width: 100%;
  @media(max-width:700px){
    font-size:1.5rem;
  }
  text-align:center;
`;
const GoogleButtonStyled = styled(Google)`
  width: 100%;
`;
const Wrapper = styled.div`
  max-width: 420px;
  color: black;
  background-color: rgba(255,255,255,0.5);
  padding: 5px;
`;
const ATag = styled.a`
padding-top: 10px;
  &:hover{
    cursor:pointer;
    color: grey;
  }
`;
const ForgotPasswordTag = styled.a`
  float: left;
  width: 100%;
  font-size: 15px;
  text-align: left;
  margin-top: -5px;
  margin-bottom: 10px;
  left: 3px;
  &:hover{
    cursor:pointer;
    color: grey;
  }
`

const LoginForm :FC<LoginFormProps> = ({navigateTo})=>{
    let navigate = useNavigate();

    const { setUser, setToken } = useUser();
    const [isCreateAccount, setIsCreateAccount] = useState<boolean>(false);
    const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
  
    const [isFailedLogInAttempt, setIsFailedLogInAttempt] = useState<boolean>(false);
  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    
  
    const { fetchData } = useFetch<LoginResponse>(API_URL+'/login', 'POST', { email, password });
  
    const [repeatPassword, setRepeatPassword] = useState('');
  
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
    const handleRepeatPasswordChange = (e: ChangeEvent<HTMLInputElement>) => setRepeatPassword(e.target.value);
  
    const handleKeyPress = (event: React.KeyboardEvent, handler: () => void) => {
      if (event.key === 'Enter') {
        handler();
      }
    }
  
  
    const accountCreationBodyData = { email, password };
    const { data: createAccountData, loading: createAccountLoading, error: createAccountError, fetchData: fetchCreateAccountData } = useFetch<CreateAccountResponse>(API_URL+'/api/create_account', 'POST', accountCreationBodyData);
  
    useEffect(() => {
      accountCreationBodyData.email = email;
      accountCreationBodyData.password = password;
    }, [email, password]);

  
    const handleSignIn = () => {
      fetchData().then(async fetchedData => {
        if (fetchedData?.message ==='Login successful') {
          setToken(fetchedData.token);
          setUser({email: email, name:'test',type_of_login: 'email', google_id:'', authenticated:true, createdat:new Date(), tier:''})

          navigate(navigateTo);

          console.log('the login was successful');
        } else {
          setIsFailedLogInAttempt(true);
          console.log("login unssuccessful: ", fetchedData?.message);
        }
      }).catch(error => {
        console.error(error);
        setIsFailedLogInAttempt(true);
      });
    }
    const handlePasswordReset = async () => {
      try {
        const response = await fetch(`${API_URL}/send_forgot_password_email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
  
        if (response.ok) {
          // Email sent successfully
          const result = await response.json();
          console.log(result); // You can do something with the response if needed
        } else {
          // Email sending failed
          const errorResult = await response.json();
          console.log(errorResult.message); // Set an error message based on the response
        }
      } catch (error) {
        // Handle network or other errors
        console.log('An error occurred. Please try again.');
      }
    };
  
    const handleCreateAccount = () => {
      if (password !== repeatPassword) {
        // handle non-matching passwords
        console.error('Passwords do not match');
        return;
      }
    
      const accountCreationBodyData = { email, password };
      // Make a request to the create_account endpoint
      fetch(API_URL+'/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(accountCreationBodyData),
      })
        .then((response) => {
          if (response.status === 201) {
            return response.json(); // Return the JSON promise for the next .then block
          } else {
            throw new Error('Account creation failed');
          }
        })
        .then((data) => {
          // Process the data from the successful response
          setToken(data.token);
          navigate('/create');
        })
        .catch((error) => {
          console.error(error);
          // handle account creation error
        });
    };
    
  

    return (
        <Wrapper>


        
        {!isCreateAccount ? (

          <InputWrapper>
          <Header>Sign In to change your reality</Header>
              <GoogleDivStyled >
                  <GoogleButtonStyled />
              </GoogleDivStyled>
              <br/>
            <Input 
              type="text"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={(e:any) => handleKeyPress(e, handleSignIn)}
            />
            {(email === '' && isFailedLogInAttempt) && <div>You need to enter a valid email</div>}

            <Input 
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={(e:any) => handleKeyPress(e, handleSignIn)}
            />
            <ForgotPasswordTag  onClick={() => {setIsForgotPassword(true); setIsCreateAccount(true)}}>Forgot Password</ForgotPasswordTag>
            {(password === '' && isFailedLogInAttempt) && <div>You need to enter a password</div>}

            {isFailedLogInAttempt && <div>Incorrect username or password</div>}
            <ButtonsWrapper>
              <ButtonStyled variant="primary" onClick={handleSignIn}>Log In</ButtonStyled>
              <br/>
              <ATag  onClick={() => setIsCreateAccount(true)}>Create Account</ATag>
            </ButtonsWrapper>
          </InputWrapper>
        ) : isForgotPassword ? (
          <InputWrapper>
          <Header>Request Password Reset</Header>
          <Input 
            type="text"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            onKeyDown={(e:any) => handleKeyPress(e, handlePasswordReset)}
          />
          <ButtonStyled variant="primary" onClick={handlePasswordReset}>Reset Password </ButtonStyled>
          <br/>
          <ATag onClick={() => {setIsCreateAccount(false);setIsForgotPassword(false);}}>
            I rememberd my password
          </ATag>
          <br/>
          <ATag  onClick={() => {setIsCreateAccount(true);setIsForgotPassword(false);}}>Create Account</ATag>
          </InputWrapper>
        ): (
          <InputWrapper>
          <Header>Sign In to change your reality</Header>
              <GoogleDivStyled >
                  <GoogleButtonStyled />
              </GoogleDivStyled>
              <br/>

          <Input
            type="text"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            onKeyDown={(e: any) => handleKeyPress(e, handleCreateAccount)}
          />
          {email === ''  && isFailedLogInAttempt && <div>You need to enter a valid email</div>}
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={(e: any) => handleKeyPress(e, handleCreateAccount)}
          />
          {password === '' && isFailedLogInAttempt && <div>You need to enter a password</div>}
          <Input
            type="password"
            placeholder="Repeat password"
            value={repeatPassword}
            onChange={handleRepeatPasswordChange}
            onKeyDown={(e: any) => handleKeyPress(e, handleCreateAccount)}
          />
          {password !== repeatPassword  && isFailedLogInAttempt && <div>Passwords do not match</div>}
          <ButtonsWrapper>
            <ButtonStyled   variant="primary" onClick={handleCreateAccount}>
              Create Account
            </ButtonStyled>
            <ATag onClick={() => setIsCreateAccount(false)}>
              Already have an account
            </ATag>
          </ButtonsWrapper>
        </InputWrapper>
        )}
        <br/>

        </Wrapper>
    )
};
export default LoginForm;