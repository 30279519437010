import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TabHeader from './GalleryComponents/TabHeader';
import Tab from './GalleryComponents/Tab';
import GalleryTab from './GalleryComponents/GalleryTab';
import { useUser } from '../../Hooks/Context/UserContext';
import LogIn from '../LogIn/LogIn';
import LoginForm from '../LogIn/LoginForm';
import LoginButtonOpensForm from '../LogIn/LoginButtonOpensForm';
import {useAuth} from '../../Hooks/Context/AuthenticationContext'

const Wrapper = styled.div`
    padding-top:20px;
    @media(max-width:700px){
      padding:5px;
      margin-top: 2vw;
    }
`;
const TabHeadersWrapper = styled.div`
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 30%; /* Adjust the width to control the thickness at the center */
        height: 1px;
    }
    @media (max-width: 700px) {
        text-align: center;
    }
`;
const LoginFormWrapper = styled.div`
    width: 100%;
    text-align:center;
    display: flex;
    justify-content: center;
    align-items:center;
    border: 1px solid grey;
    @media(max-width: 800px){
      width: 100%;
    }
    @media(min-width: 1000px){
      width: 60%;
      margin: auto;
    }
    margin-top: 20px;
    border-radius: 5px;
`;

const AlignedLogInButton = styled.div`
    left:20px;
    display:block;
`;

const Gallery: React.FC = () => {
  const {  token  } = useUser();
  const [activeTab, setActiveTab] = useState<string>('My Images');


  useEffect(() => {
    // Check if token is null
    if (token === null || token === '') {
      setActiveTab('Community');
    } else {
      setActiveTab('My Images');
    }
  }, [token]);


  return (
    <Wrapper>
      <TabHeadersWrapper>
      <TabHeader label="My Images" activeTab={activeTab} onClick={setActiveTab} />
        <TabHeader label="Community" activeTab={activeTab} onClick={setActiveTab} />
        
      </TabHeadersWrapper>
      <Tab label="My Images" activeTab={activeTab}>
        {token ? 
        <GalleryTab isMyImagesTab /> : 
        <LoginFormWrapper>
          <LoginForm navigateTo='./gallery'/>
        </LoginFormWrapper>
        }
      </Tab>

      <Tab label="Community" activeTab={activeTab}>
        <GalleryTab isCommunityTab />
      </Tab>
    </Wrapper>
  );
};

export default Gallery;
