import React, { useEffect, useState, useRef } from 'react';
import { useUser, User } from '../../Hooks/Context/UserContext';
import { GoogleLogin, useGoogleLogin  } from '@react-oauth/google';
import { useAuth } from '../../Hooks/Context/AuthenticationContext';
import useFetch from '../../Hooks/useFetch';
import { usePopupContext } from '../../Hooks/Context/PopUpContext';
import styled from 'styled-components';
import {API_URL} from '../../urls';
import {useNavigate} from "react-router-dom";

interface LoginResponse {
  success: boolean;
  user: User;
  token: string;
}

const GoogleButtonStyled = styled(GoogleLogin)`
  width: 100% !important;
`;
const Wrapper = styled.div`
  width: 100%;
`;


const Google = () => {
  const [crResponse, setCredentialResponse] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const { setUser,setToken } = useUser();
  const { fetchData } = useFetch<LoginResponse>(API_URL+'/google_auth', 'POST', {crResponse});

  const {closePopup} = usePopupContext();
  const [isFailedLogInAttempt, setIsFailedLogInAttempt] = useState<boolean>(false);

  let navigate = useNavigate();

  const onLogin = useGoogleLogin({
    onSuccess: tokenResponse => console.log(tokenResponse),
    scope: 'openid profile email https://www.googleapis.com/auth/userinfo.profile'
  });
  const onSuccess = (response:  any) => {
    console.log('Google login successful:', response.credential);

    // Send the OAuth token to your Flask backend for verification
    fetch(API_URL+'/google_auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ credentials: response.credential }),
    })
  
      .then((res) => res.json())
      .then((data) => {
        console.log('data',data)
        if (data.status === 'success') {
          // Backend authentication successful, you can continue with your login logic here

          setToken(data.token)
          setLoggedIn(true);
          navigate('/create');
        } else {
          // Handle authentication failure
          console.error('Authentication failed:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error during authentication:', error);
      });
  };


  const handleSignIn = () => {
    console.log(crResponse);
    fetchData().then(async fetchedData => {
      if (fetchedData?.success) {
        closePopup();
        setUser(fetchedData.user);
        setToken(fetchedData.token);
        console.log('the login was successful');

      } else {
        setIsFailedLogInAttempt(true);
        console.log(fetchedData?.token);
      }
    }).catch(error => {
      console.error(error);
      setIsFailedLogInAttempt(true);
    });
}


useEffect(() => {
  // You can redirect or perform any actions after successful login
  if (loggedIn) {
    // For example, redirect to the home page
  }
}, [loggedIn]);
  
useEffect(() => {
  if (crResponse) {
    handleSignIn();
  }
}, [crResponse]);

const parentRef = useRef<HTMLDivElement | null>(null);

const [buttonWidth, setButtonWidth] = useState('290px');

const [screenWidth, setScreenWidth] = useState(window.innerWidth);

useEffect(() => {
  const handleResize = () => {
    // Update the screen width when the window is resized
    setScreenWidth(window.innerWidth);
  };

  // Listen for the resize event
  window.addEventListener('resize', handleResize);

  // Cleanup the event listener when the component is unmounted
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

useEffect(() => {
  // Access the width of the parent element in pixels
  if (parentRef.current) {
    const parentWidth = parentRef.current.offsetWidth;
    setButtonWidth((parentWidth-5)+'px');
  }
}, [screenWidth]); 



    return (
          <Wrapper ref={parentRef}>
            <GoogleButtonStyled
            onSuccess={onSuccess}
            onError={() => {
              console.log('Login Failed');
            }}
            width={buttonWidth}
            size='large'
            text = 'continue_with'
            logo_alignment='center'
            useOneTap
          />
        </Wrapper>
    )
}

export default Google;