import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../../assets/white-concrete-wall.jpg';
import Carousel from './ThreeDCarousel';

const Header = styled.h1`
    font-size:50px;
    width:45%;
    font-family:MikelaRegular;
    font-weight: lighter;
    z-index:1;
    overflow: hidden;
    @media(min-width: 1200px){
        margin-top: -20px;
    }
    @media(max-width: 800px){
        width: 80%;
        font-size:30px;
    }
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 800px){
        height: 82vh;
        marg0px;
        justify-content: center;
        position:absolute;
    }
`;
const PageWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Background = styled.div`
    position: absolute;
    background-image: url(${BackgroundImage});
    opacity: 0.7;
    height: 100vh;
    width: 100%;
    top: -25px;
    @media(max-width: 800px){
        top:-20%;
    }
`;


const CarouselPage = () =>{
    return(
        <PageWrapper>
            <Wrapper>
                <Background/>
                <Header>Click to become the characters you admire</Header>
                <Carousel/>
            </Wrapper>
        </PageWrapper>
    )

}
export default CarouselPage;