import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import ImageListItem from '@mui/material/ImageListItem';
import ShareButtons from './CardStackCompontents/ShareButtons';
import Overlay from './CardStackCompontents/CardStackOverlay';
import { EffectsDataContextProvider, useEffectsDataContext } from '../../../Hooks/Context/EffectsDataContext';
interface CardStackProps {
  imagesArray: any[];
  effect_used: string;
}

type ImageCardProps = {
  index: number;
  borderColor: string; 
}

const Wrapper = styled.div`
  width: 100%;
  display:flex;
  height: 600px;
  justify-content: center;
  align-items:center;
  flex-direction: column; 
  text-align:center;
  margin-left:-15px;
  @media(max-width: 1100px){
    height: 320px;
  }
  @media(max-width: 800px){
    height: 200px;
    margin-left: -25px;
  }
`;

const StackWrapper = styled.div`
  width: 100%;
  position: relative;
  display:flex;
  height: 370px;
  justify-content: center;
  align-items:center;
  flex-direction: column; 
  top: 0;
  @media(max-height: 700px){
    height: 300px;
  }
  @media(max-width: 800px){
    height: 150px;
    width: 75%;
  }
`;

const ImageCardWrapper = styled.div`

`;


const ImageCard = styled.img<ImageCardProps>`
  cursor: pointer;
  border-radius: 5%;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1.5;
  position: absolute;
  object-fit: cover;
  border: solid ${({ borderColor }) => borderColor} 1px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
  top: ${({ index }) => index * 0}px;  
  left: ${({ index }) => index * 17}px;
  z-index: ${({ index }) => index};
  &:hover {
    background-color: grey;
  }
  @media(min-width: 810px){
    width: 120%;

  }
  @media(min-width: 1200px){
    width: 80%;
    left: ${({ index }) => index * 40}px;
  }
`;

const ShareButtonsSection = styled.div`
  background-color: rgba(0,0,0,0.05);
  width: 120%;
  height: 100px;
  border-radius: 5%;
  @media(min-width: 1200px){
    margin-top: 20px;
  }

`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 70%;
`;
const EffectName = styled.span`
  padding-top: 0px;
  margin-top: -30px;
  text-align:center;
  font-size: 30px;
  width: 120%;
  @media( min-width: 1150px){
    margin-top: 0px;
  }
  @media(max-width: 1150px){
    width: 160%;
    margin-right: -60%;
    font-size: 20px;
  }
  @media(max-width: 700px){
    font-size: 15px;
    margin-top: 10px;
  }
`;

const CardStack: FC<CardStackProps> = ({ imagesArray, effect_used }) => {
  const borderColors = ["lightgreen", "#e6c6ad", "#a18a79", "#799ba1", "lightblue", 'white', 'lightyellow', 'lightcyan', '#f0e0bc', '#bce0f0'];

  const randomColor = borderColors[Math.floor(Math.random() * borderColors.length)]
  const [loadedImages, setLoadedImages] = useState<number[]>([]);

  const { getEffectNameById } = useEffectsDataContext();

  const handleImageLoad = (index: number) => {
    setLoadedImages(prevState => [...prevState, index]);
  };
 
  
  return (

    <Wrapper>

      <Container>
          <StackWrapper>
            {imagesArray.map((image, index) => (
              <ImageCardWrapper
                key={index}>
                <ImageCard
                  src={image}
                  index={index}
                  borderColor={randomColor}
                  loading="lazy"
                />
              </ImageCardWrapper>

            ))}
          </StackWrapper>
          <EffectName>{getEffectNameById(effect_used)}</EffectName>
      </Container>
    </Wrapper>
  );
};

export default CardStack;
