import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled.div`
  width: 95%;
  height: 300px;
  display: flex;
  text-align:center;
  justify-content:center;
  align-items: center;

  @media(max-width: 700px){
    height: 220px;
  }
`;
const StyledBox = styled(Box)`
    margin-top: 10%;
    border-radius:5%;
    width: 70%;
    height: 90%;
    padding: 10px;
    justify-content:center;
    border: 1px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
    border-radius: 3%;
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
    flex-direction: column;

    @media(max-width: 700px){
        aspect-ratio:1/1.5;
    }
`;
const RedText = styled.span`
    color: red;
`


const FailedToGenerate = () => {
    return(
        <Wrapper>
            <StyledBox>
                Our advanced engines could not copy a face as special as yours!! Please try again
            </StyledBox>
        </Wrapper>
    )
}
export default FailedToGenerate;