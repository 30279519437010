import React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import {useCreationProcessContextValue} from '../../../../Hooks/Context/CreationProcessContext';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { useNavigate } from 'react-router-dom';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


interface FABProps {
  onEffectButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
  effectButtonClicked: boolean;
}

const CircularImage = styled.img`
    width: inherit;
    height: inherit;
    border-radius: 50%;
`;

const ElementText = styled.p`
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
`;
const Wrapper = styled(Box)`
    background-color: rgba(255,255,255,0.2);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 12%;
    right: 16%;
    justify-content: center;
    align-items: center;
    font-family: 'MikelaRegular';
    @media(max-width: 1100px){
      right: 14%;
    }
    @media(max-width: 700px){
        position: relative;
        right: 2%;
        top: 0%;
        flex-direction: row;
        padding-bottom: 20px;
        border: lightgrey 1px solid;
    }
`;

const StyledFab = styled(Fab)`
  width: 80px !important;
  height: 80px !important;
`;
const EffectIcon = styled(AutoAwesomeIcon)`
    border-radius: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7b1fa2;

`;

const ChooseAnEffectNote = styled.h3`
    border-radius: 10px;
    padding: 30px;
    font-family: 'MikelaRegular';
    background-color: rgba(255,255,255,0.7);
    position: absolute;
    width: 100px;
    left: 0%;
    top: 50%;
    color: #9C27B0;
    z-index: 2000;
    @media(max-width: 700px){
      position: absolute;
      left: 0%;
      top: 40%;
    }
`;
const NoteArrow = styled(CallReceivedIcon)`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 1000px;
`;
const StyledButton = styled.button`
    border-radius: 20px;
    background-color: #7B1FA2;
    font-size: 20px;
    color:white;
    cursor: pointer;
    padding: 7px;
`;


const FloatingActionButtons: React.FC <FABProps> = ({onEffectButtonClicked, effectButtonClicked}) =>{

  const { effectName,
    selectedImage,
    addImage,
    selectedEffectId,
    completedCreation,
    imageId,
    loading,
    effectImage } = useCreationProcessContextValue();

    let navigate = useNavigate();

  const userSection = (selectedImage: any) => {
    if (selectedImage) {
      return (
        <SectionWrapper>
          <ElementText>Image you picked</ElementText>
          <StyledFab aria-label="add">
            <CircularImage  src={selectedImage.toString()} alt="Description" />
          </StyledFab>
        </SectionWrapper>
      );
    }
  }
  
  const effectSection = (effectName: any, effectImage:any, selectedImage: any, effectButtonClicked: boolean) => {

    if (effectName){
      return(
      <SectionWrapper>
        <ElementText>You picked {effectName}</ElementText>
        <StyledFab color="secondary" aria-label="edit">
            <CircularImage src={effectImage} alt="Description" />
        </StyledFab>
        {effectButtonClicked?(
          <ChooseAnEffectNote>
            Please pick an effect from the list
            <NoteArrow style={{ fontSize: '24px' }}/>
          </ChooseAnEffectNote>
        ):(<></>)}
      </SectionWrapper>
      );
    } else if(selectedImage){
      return(
      <SectionWrapper>
        <ElementText>Choose a character</ElementText>
        <StyledFab disabled color="secondary" aria-label="edit">
          <EffectIcon onClick={()=>onEffectButtonClicked(true)}/>
        {effectButtonClicked?(
          <ChooseAnEffectNote>
            Please pick an effect from the list
            <NoteArrow style={{ fontSize: '30px' }}/>
          </ChooseAnEffectNote>
        ):(<></>)}
        </StyledFab>
      </SectionWrapper>
      );
    }
  }

  const handleImageCreationRequest = () =>{  
    completedCreation();
    navigate('/finished');
};


  
  const createButton = (effectName: any, selectedImage: any) => {
    if(effectName && selectedImage){
      return(
        <SectionWrapper>
          <ElementText>Create your images</ElementText>
          <StyledButton  color="secondary" aria-label="edit" onClick={handleImageCreationRequest}>
              Create
          </StyledButton>
        </SectionWrapper>
  
      );
    }
    else if(effectName){
      return(
        <SectionWrapper>
          <ElementText>Create your images</ElementText>
          <StyledFab disabled>
              <QuestionMarkIcon />
          </StyledFab>
        </SectionWrapper>
      )
    }
  }
  

    
    
    return(
        <Wrapper sx={{ '& > :not(style)': { m: 1 } }}>
              {userSection(selectedImage)}

              {effectSection(effectName, effectImage, selectedImage, effectButtonClicked)}
              {createButton(effectName, selectedImage)}

        </Wrapper>
    )
}
export default FloatingActionButtons;