import React, {useMemo,useContext} from 'react';
import {useNavigate} from "react-router-dom";
import styled from 'styled-components';
import {NavigationLink} from '../NavigationLink';


var ItemsToShow = [
    { name: 'OurEffects', path: '/ourEffects' },
    { name: 'Gallery', path: '/gallery' },
    { name: 'Create', path: '/create' },
    { name: 'Support', path: '/support' }
  ];

const Wrapper = styled.div`
    width: 100%;
    background-color: #480CA8;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1000;
    height: 4vh;

    font-family: 'MikelaRegular';
`;

const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RowOfText = styled(Row)`

@media(max-width:600px){
    text-align:center;
    display:inline-block;
}
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
`;

const Title = styled.h1`
    font-size: 30px;
    width:200px;
`;

const Separator = styled.hr`
    background-color: white;
    height: 1px;
    width: calc(100% - 70px);
`;
const NonClickableElement = styled.span`
    margin: 10px;
`;

const Footer = () =>{
    let navigate = useNavigate();

    return (
        <Wrapper>
            <NonClickableElement>
                @copyright Sleema
            </NonClickableElement>
            <NonClickableElement>
                generatemeai@gmail.com
            </NonClickableElement>
        </Wrapper>
    )
}

export default Footer;
