import React, {useEffect, useState} from 'react';
import {BsCircle} from 'react-icons/bs';
import styled,  { keyframes } from 'styled-components';
import {useCreationProcessContextValue} from '../../../../Hooks/Context/CreationProcessContext';

import {useEffectsDataContext} from '../../../../Hooks/Context/EffectsDataContext';

import after from '../../../../assets/FlipBook/TonyLordOfTheRingsEdited.png';
import before from '../../../../assets/FlipBook/tony_base_image_closeup.jpg';

import image1 from '../../../../assets/statue+jellyfish.jpg';
import image2 from '../../../assets/statue+flowers.jpg';
import {useCurrentEffectGroupContext} from '../../../../Hooks/Context/CurrentEffectGroupContext';
import { setDefaultNamespace } from 'i18next';

import { FaArrowAltCircleRight,FaArrowAltCircleLeft } from "react-icons/fa";

import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../../urls';
import { useUser } from '../../../../Hooks/Context/UserContext';
import { IoIosStar } from "react-icons/io";


// Type for the effect
type Effect = {
    name: string;
    image: string;
    id: number;
}

// Type for the effect group
type EffectGroup = {
  text: string;
  color: string;
  effects: Effect[];
}
// Type for the circles of the slider
type IconProps = {
    isactive: string;
    activecolor: string;
};

type EffectProps = {
  effect_category: string;
  effect_id: string;
  effect_image_url: string;
  effect_name: string;
  quantity_of_use: number;
  tier: string;
};


const ribbonAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;
const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex; 
  align-items: center; 
  flex-direction: column;
  
  transition: transform 0.5s ease; 
`;
const StyledIcons = styled(BsCircle)<IconProps>`
    background-color:${props => props.isactive === 'true' ? props.activecolor : 'lightgrey'};
    color:${props => props.isactive ==='true' ? props.activecolor : 'lightgrey'};
    position: relative;
    display: inline-block;
    border-style:none;
    border-radius:50%;
    margin-right: 2px;
    cursor: pointer;
    margin-bottom: 10px;

`;
const StyledIconsWrapper = styled.div`
  display:flex;
`;
const StyledIcondToolTip = styled.div`
  display: none;
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  bottom: 125%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
`
const EffectsBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */
  grid-template-rows: repeat(2, 1fr); /* Creates 2 rows */
  gap: 10px; 
`;
const EffectsContainer = styled.div`
  width: 97%;
  align-self: center;
  overflow: hidden;
  border: 20px;
  text-align:center;
  justify-content: center;
`;
const EffectCard = styled.button`
  position: relative;
  background-color: unset;
  border-color: unset;
  border-radius: 15%;
  width: 95%;
  height: 95%;
  cursor: pointer;
  padding: 0px;
  border:#bda6e0 solid 1px;
  overflow: hidden;
`;
const EffectCardImage = styled.img`
  width:100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;

  
`;
const EffectCardName = styled.p`
  position: absolute;
  width: 101%;
  height: 30%;
  bottom:0px;
  left: 50%;
  text-align: bottom !important;
  transform: translate(-50%, 50%);
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
  z-index: 3;
  color:white;
  border-radius: 0px 0px 25px 25px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  text-align: bottom;

  @media(min-width: 1000px){
    font-size: 1rem;
  }

`;

const EffectGroupText = styled.h2<{ color: string }>`
  color: ${(props) => props.color};
  padding: 0px;
  line-height: 0px;
  @media(min-width: 1000px){
    font-size: 2rem;
  }
`;
const AfterArrow = styled(FaArrowAltCircleRight)`
  color: #480CA8;
  margin-left: 10px;
  &: hover{
    cursor: pointer;
    color: #D790F4
  }
`;
const BeforeArrow = styled(FaArrowAltCircleLeft)`
  color: #480CA8;
  margin-right: 10px;
  &: hover{
    cursor: pointer;
    color: #D790F4
  }
`;
const EffectGroupTitleAndControls = styled.div`
  display: flex;
  align-items: center;


`;
const EffectCardForPayedElements= styled.div`
  position: relative;
  background-color: unset;
  border-color: unset;
  border-radius: 15%;
  width: 95%;
  height: 95%;
  cursor: pointer;
  padding: 0px;
  border:#bda6e0 solid 1px;
  overflow: hidden;
`;


const RibbonWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;
  height: 100%;

  width: 100%;
  background-color: rgba(0,0,0,0.5);
  border-radius: inherit;

`;

const ProRibbon = styled.div`
  background-color: #ffcc00;
  color: white;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 3px;
`;
const ProIcon = styled.p`
  padding: 0px;
  line-height: 0px;
  position:absolute;
  right: 5px;
  top: -5px;
  color: gold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const PaymentOverlay = styled.div`
  color: white;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 3px;
`;
const LimitReached = styled.button`
  border: 1px solid gold;
  border-radius: 20px;
  margin-top: 20px;
  padding: 20px;
  position: absolute;
  background-color: gold;
  top: 25%;
  font-size: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 70%;
  z-index:3000;
  cusor: pointer;
  font-family:MikelaRegular;
  @media(max-width: 800px){
    top: 12%;
  }

`;
const StyledLimitReachedButton = styled.button`
  border-radius: 20px;
  background-color: #7B1FA2;
  font-size: 20px;
  color:white;
  cursor: pointer;
  padding: 7px;
`;

const EffectGroupSlider: React.FC = () => {
    const {setEffectName,setEffectImage,
       hasStarted,
      setStarted,
      selectedImage,
      addImage,
      selectedEffectId,
      addEffect,
      hasCompletedAnImage,
      completedCreation } = useCreationProcessContextValue();
    const [effects, setEffects] = useState<EffectProps[]>([]);
    const {changeName, getName} = useCurrentEffectGroupContext();
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [isAllowedToCreate, setIsAllowedToCreate] = useState(true);
    const [error, setError] = useState(null);
    const {token} = useUser();
    const [resetTimer, setResetTimer] = useState(false);

    let navigate = useNavigate();

    const [currentEffectGroup, setCurrentEffectGroup] = useState<number>(0);
    const {categories, setEffectsFromArray} = useEffectsDataContext();


    const [transformationQuantity, setTransformationQuantity] = useState<number>(0);
    const transitionDuration = 500; 
    



    const handleEffectChoosing = (index: string, name: string, effect_image_url: string) => {
      console.log(1);
      setEffectName(name);
      addEffect(index);
      setEffectImage(effect_image_url);
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_URL}/is_user_allowed_to_create?token=${token}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // Add any other headers as needed
            },
          });
    
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
    
          const data = await response.json();
          setSubscriptionData(data.subscriptionType);
          // Check if the user is allowed to create based on the response
          if (!data.is_user_allowed_to_create) {
            setIsAllowedToCreate(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
    
      fetchData();
    }, []); 

  
    
    const effectGroups: EffectGroup[] = [
        {
          text: 'Fashion',
          color: 'gold', // Red color
          effects: [
            {
              name:'Elsa From Frozen',
              image: before,
              id: 0,
            },
            {
                name:'Harry Potter',
                image: before,
                id: 1,
            },
            {
                name:'Super Woman',
                image: before,
                id: 2,
            },
            {
                name:'Luke Skywalker',
                image: before,
                id: 3,
            }
          ],
        },
        {
          text: 'Anime',
          color: '#008086', // Green color
          effects: [
            {
                name:'Banksy',
                image: image1,
                id: 4,
            },
            {
                name:'French new Wave',
                image: image1,
                id: 5,
            },
            {
                name:'Van Gogh',
                image: image1,
                id: 6,
            },
            {
                name:'Wes Anderson',
                image: image1,
                id: 7,
            },
          ],
        },
        {
          text: 'Art',
          color: '#c7fcec', // Blue color
          effects: [
            {
                name:'Balenciaga',
                image:before,
                id: 8,
            },
            {
                name:'Bohemian',
                image:before,
                id: 9,
            },
            {
                name:'Avan Garde',
                image:before,
                id: 10,
            },
            {
                name:'Dior Champs Elysees',
                image:before,
                id: 11,
            },
          ],
        },
        {
          text: 'Fiction',
          color: '#7E2D00', // Blue color
          effects: [
            {
                name:'Archer',
                image: before,
                id: 12,
            },
            {
                name:'Captain Tsubasa',
                image: before,
                id: 13,
            },
            {
                name:'Rick and Morty',
                image: before,
                id: 14,
            },
            {
                name:'Captain Tsubasa',
                image: before,
                id: 15,
            },
          ],
        },
      ];
      const [currentColor, setCurrentColor] = useState<string>(effectGroups[currentEffectGroup].color);
      
      const effectsGroupLength = effectGroups.length;
      const handleCircleClick = (index: number)=>{
        setCurrentEffectGroup(index);
      };


      useEffect(() => {
        const interval = setInterval(() => {
          transition(100, 0, transitionDuration, setTransformationQuantity);
          if (currentEffectGroup < effectsGroupLength - 1) {
            setCurrentEffectGroup((prev) => prev + 1);
            setCurrentColor(effectGroups[currentEffectGroup + 1].color);
            changeName(categories[currentEffectGroup]?.categoryName);
          } else {
            setCurrentEffectGroup(0);
            setCurrentColor(effectGroups[0].color);
          }
        }, 12000);
    
        return () => clearInterval(interval);
      }, [currentEffectGroup, resetTimer]);
    
      const handleBoxClick = () => {
        setResetTimer(true);
        setTimeout(() => setResetTimer(false), 12000);
      };
    



    useEffect(()=>{
      if(categories.length === 0){
        fetch(API_URL + '/effects')
          .then(response => response.json())
          .then(data => {
            setEffectsFromArray(data);
            setEffects(categories[currentEffectGroup].effects);
          })
          .catch(error => console.error('Error:', error));
      }else{
        setEffects(categories[currentEffectGroup].effects);
      }

    },[currentEffectGroup]);

    useEffect(() => {
      const delayedEffect = setTimeout(() => {
        setCurrentEffectGroup(1);
        setCurrentColor(effectGroups[1].color);
      }, 1000);
    
      return () => clearTimeout(delayedEffect);
    }, []);


const transition = (startValue:any, endValue:any, duration:any, callback:any) => {
  const interval = 16;
  

  const steps = Math.ceil(duration / interval);
  const stepValue = (endValue - startValue) / steps;

  let currentValue = startValue;


  const update = () => {


    if ((startValue < endValue && currentValue <= endValue) || (startValue > endValue && currentValue >= endValue)) {
      currentValue += stepValue;
      callback(currentValue);
      setTimeout(update, interval);
    } else {
      // Transition reached the end, set transformationQuantity to 0
      callback(0);
    }
  };
  update();
};

    const handleArrowClick = (previousOrNext: string) =>{
      

      if (previousOrNext === 'previous') {
        transition(-100, 0, transitionDuration, setTransformationQuantity);
      } else {
        transition(100, 0, transitionDuration, setTransformationQuantity);
      }
      if(previousOrNext=='previous'){
        if(currentEffectGroup === 0){
          setCurrentEffectGroup(effectsGroupLength-1);
          setCurrentColor(effectGroups[effectsGroupLength-1].color);
        }else{
          setCurrentEffectGroup(currentEffectGroup-1);
          setCurrentColor(effectGroups[currentEffectGroup-1].color);
        }
      }else{
        if(currentEffectGroup === effectsGroupLength-1){
          setCurrentEffectGroup(0);
          setCurrentColor(effectGroups[0].color);
        }else{
          setCurrentEffectGroup(currentEffectGroup+1);
          setCurrentColor(effectGroups[currentEffectGroup+1].color);
        }
      }
    };

    const [touchStartX, setTouchStartX] = useState<number>(0);
    const [touchEndX, setTouchEndX] = useState<number>(0);


    const handleTouchStart = (e: React.TouchEvent) => {
      setTouchStartX(e.touches[0].clientX);
    };
  
    const handleTouchMove = (e: React.TouchEvent) => {
      setTouchEndX(e.touches[0].clientX);
    };
  
    const handleTouchEnd = () => {
      const swipeDistance = touchEndX - touchStartX;

      if(touchEndX !=0 && touchStartX != 0){
        if (swipeDistance > 0) {
          // Swiped to the right
          handleArrowClick('previous');
        } else if (swipeDistance < 0) {
          // Swiped to the left
          handleArrowClick('next');
  
        }
      }
      setTouchStartX(0);
      setTouchEndX(0);
    };
    



    return(

        <Wrapper
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onClick={handleBoxClick}>

          {
            (!isAllowedToCreate && subscriptionData === 'Free Tier')?(
            <LimitReached onClick = { () => navigate('/PremiumPage')}>
            <p>You have reached the limit of free images please subscribe</p>
            <StyledLimitReachedButton >Subscribe</StyledLimitReachedButton>
            </LimitReached>
            ):(!isAllowedToCreate)?(
              <LimitReached onClick = { () => navigate('/PremiumPage')}>
              <p>You have reached the limit of payed images please subscribe again</p>
              <StyledLimitReachedButton >Subscribe</StyledLimitReachedButton>
              </LimitReached>
            ):(<></>)
          }
          <EffectGroupTitleAndControls>
            <div onClick={() => handleArrowClick('previous')}>
            <BeforeArrow size={20} color={currentColor} />
            </div>
            <EffectGroupText color={currentColor}>{categories[currentEffectGroup]?.categoryName}</EffectGroupText>
            <div onClick={() => handleArrowClick('next')}>
              <AfterArrow size={20} color={currentColor} />
            </div>
          </EffectGroupTitleAndControls>
            <StyledIconsWrapper>
              {
                categories.map((Group, index) => (
                <StyledIcons size={11} isactive={(index===currentEffectGroup).toString()} activecolor={currentColor} onClick={()=>{handleCircleClick(index)}} key={index}/>
                ))
              }
          </StyledIconsWrapper>

              <EffectsContainer>
          <EffectsBox
      style={{
        transform: `translateX(${transformationQuantity}%)`,
      }}>
            {

                isAllowedToCreate? (

              effects.map((e,index) => (
                  <EffectCard onClick={() => { handleEffectChoosing(e.effect_id, e.effect_name, e.effect_image_url) }} key={index}>
                    <EffectCardImage src={e.effect_image_url} />
                    <EffectCardName>{e.effect_name}</EffectCardName>
                  </EffectCard>
              ))
                ) : (
                  effects.map((e,index) => (
                  <EffectCardForPayedElements key={index} 
                  onClick = { () => navigate('/PremiumPage')}>
                    <EffectCardImage src={e.effect_image_url} />
                    <EffectCardName>{e.effect_name}</EffectCardName>
                    <RibbonWrapper>
                      <PaymentOverlay>
                        <ProIcon><IoIosStar />Pro</ProIcon>
                      </PaymentOverlay>
                    </RibbonWrapper>
                  </EffectCardForPayedElements>
                )))
            }

          </EffectsBox>
          </EffectsContainer>
        </Wrapper>
    )
}
export default EffectGroupSlider;