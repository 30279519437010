import React, {useState, useEffect, FC} from 'react';
import styled from 'styled-components';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {API_URL} from '../../../urls';
import { useEffectsDataContext } from '../../../Hooks/Context/EffectsDataContext';



interface InProgressImagesProps {
    image_id: string | null;
    triggerUpdate: () => void; 
    effect_used: string;
    original_image: string | File;
}

interface LinearProgressWithLabelProps extends LinearProgressProps {
  effect_name: string;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, effect_name: string | undefined }) {

  const { value, effect_name } = props;
  const buffer = value + 10;
  

  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      
      <br />
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${value}%`}</Typography>
      </Box>
      <Box sx={{ width: '80%', mr: 1 }}>
        <LinearProgress variant="buffer" value={value} valueBuffer={buffer} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          Your <br/><b>{effect_name}</b><br/> images are being generated
        </Typography>
      </Box>
    </Box>
  );
}

const Wrapper = styled.div`
  width: 95%;
  height: 300px;
  display: flex;
  text-align:center;
  justify-content:center;
  align-items: center;

  @media(max-width: 700px){
    height: 300px;
  }
`;
const ImageWhenNoProgressBar = styled.div`
    background-color: black;
    border-radius: 5%;
    height: 70%;
    aspect-ratio:1/1;
    display: flex;
    text-align:center;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
    @media(max-width: 700px){
      aspect-ratio:1/1.5;
    }
`;
const TextWhenNoProgressBar = styled.h3`
  color: white;

`;
const StyledBox = styled(Box)`
  position: relative;
  border-radius: 5%;
  height: 70%;
  aspect-ratio: 1/1;
  justify-content: center;
  border: 1px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 3%;

  @media (max-width: 700px) {
    aspect-ratio: 1/1.5;
  }
`;
const OriginalImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const OriginalImageOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;


const InProgressImage: FC<InProgressImagesProps> = ({triggerUpdate,image_id, effect_used, original_image}) => {
    const [progress, setProgress] = useState(0);
    const [isProgressBar, setIsProgressBar] = useState<boolean>(false);
    const [showingProgress, setShowingProgress] = useState(0);
    const { getEffectNameById } = useEffectsDataContext();
    const effectName = getEffectNameById(effect_used);

    useEffect(() => {
      const fetchData = () => {
          const url = `${API_URL}/get_status?image_id=${image_id}`;
  
          fetch(url)
              .then(response => {
                  if (!response.ok) {
                      if (response.status === 404) {
                          // If 404, wait 4 seconds and retry
                          setTimeout(() => fetchData(), 4000);
                      } else {
                          throw new Error(`HTTP error! Status: ${response.status}`);
                      }
                  }
                  return response.json();
                  
              })
              .then(data => {
                  console.log(data.status);
                  if (data.status.status === "In Progress") {
                      setIsProgressBar(false);
                  } else if (data.status.status === 'finished' || data.status.status === '100') {
                      setProgress(100);
                      setShowingProgress(100);
                      triggerUpdate();
                  } else if (/\d/.test(data.status.status)) {
                      setIsProgressBar(true);
                      setProgress(parseInt(data.status.status));
                      if(showingProgress >= data.status.status && data.status.status != 0){
                        setShowingProgress(parseInt(data.status.status));

                      }
                  }
              })
              .catch(error => {
                  console.error('Fetch error:', error);
              });
      };
  
      // Initial fetch
      fetchData();
  
      // Set up interval for subsequent fetches
      const intervalId = setInterval(fetchData, 10000); // 10 seconds
  
      // Clear interval on component unmount or when the image_id changes
      return () => clearInterval(intervalId);
  
  }, [image_id, triggerUpdate]);

    //this makes sure that the progress is showing and that it's less then the 
    useEffect(() => {
      const intervalId = setInterval(() => {
        setShowingProgress(prevProgress => {
          const updatedProgress = prevProgress + 1;
    
          // Check if the updatedProgress is less than or equal to 100
          if (updatedProgress <= 100) {
            return updatedProgress;
          } else {
            clearInterval(intervalId); // Stop the interval if showingProgress is greater than 100
            return prevProgress;
          }
        });
      }, 2000)
  
      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, []);

    

    return(
        <Wrapper>
            {(isProgressBar)?(
            <StyledBox>
              <OriginalImage src={typeof original_image === 'string' ? original_image : URL.createObjectURL(original_image)} alt="your image"/>
              <OriginalImageOverlay>
                <LinearProgressWithLabel effect_name={effectName} value={showingProgress} />
                </OriginalImageOverlay>
            </StyledBox>
            ):(
                <ImageWhenNoProgressBar>
                  <Typography variant="body2" >
                        Your<br/> <b>{effectName}</b> <br/>  images are getting generated please wait
                  </Typography>
                </ImageWhenNoProgressBar>
            )}
        </Wrapper>
    )

}
export default InProgressImage;