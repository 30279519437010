import styled, {css} from 'styled-components';
import { useSpring, animated } from 'react-spring';
import {useEffectsDataContext} from '../../../Hooks/Context/EffectsDataContext';
import {useCreationProcessContextValue} from '../../../Hooks/Context/CreationProcessContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TouchSweep from 'touchsweep';
import { useNavigate } from 'react-router-dom';
import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
  useRef,
  useCallback
} from 'react';


type Effect = {
  effect_id: string;
  effect_name: string;
  effect_image_url: string;
  effect_category: string;
  quantity_of_use: number;
  tier: string;
}

interface SlideProps {
  index: number;
  len: number;
  theta: number;
  radius: number;
  selectedIndex: number;
}


export type CarouselItem = Readonly<{
  effect_id: string;
  effect_name: string;
  effect_image_url: string;
  effect_category: string;
  quantity_of_use: number;
  tier: string;
}>;

export type DecoratedCarouselItem = CarouselItem & Readonly<{ id: string }>;

export type CarouselProps = Readonly<{}>; // No need for props in this case

export type CarouselRef = Readonly<{
  next: () => void;
  prev: () => void;
  getItems: () => DecoratedCarouselItem[];
  getSelectedIndex: () => number;
  setSelectedIndex: (index: number) => void;
}>;

const Wrapper = styled.div`
  width: 100%;
  justify-content: center;
  display:flex;
  align-items: center;
  overflow: hidden;
  height: auto;
`;
const CarouselWrapper = styled.div`
  width: 20vw;
  height: 60vh;
  position: relative;
  margin: 0 auto;
  perspective: 70vw;
  display: flex;
  justify-content: center;
  @media(max-width: 800px){
    width: 35vw;
    height: 15rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
`;

const isMobile = window.innerWidth <= 800;

const calculateOpacity = (index: number, selectedIndex: number, len: number) => {
  const circularDifference = Math.abs((selectedIndex - index + len) % len);
  const secondDifference = Math.abs((index - selectedIndex + len) % len);

  if(isMobile){
    return circularDifference <= 1 || secondDifference <= 1;
  }
  return circularDifference <= 3 || secondDifference <= 3;
};

const Slide = styled.div<SlideProps>`
  position: absolute;
  width: 18vw;
  left: 1vw;
  top: 1vh;
  cursor: pointer;
  transition: transform 1s, opacity 1s;
  opacity: ${({ index, selectedIndex,len }) => (calculateOpacity(index,selectedIndex, len)? 1 : 0.5)};
  transform: ${({ index, theta, radius }) =>
    `rotateY(${theta * index}deg) translateZ(${radius}px)`};
  @media(max-width: 800px){
    width: 35vw;
  }
`;
const SlideImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  border: 1px solid black;
`;
const EffectName = styled.h2`
  font-size: 25px;
  margin-left: -10%;
  width: 120%;
  @media(max-width: 800px){
    font-size: 15px;
  }
`;
const EffectInspiration = styled.span`
  font-style: italic;
`;
const Buttons = styled.button`
  position: absolute;
  background: rgba(255,255,255,0.1);
  border: 0px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  @media(min-width: 1200px){
    top: unset;
    background:rgba(255,255,255,0.3);
    border-radius: 20%;
    text-align: center;
    display: flex;
    flex-direction: center;
    align-items: center;
    margin-top: -5%;
  }
  @media(max-width: 800px){
    position: absolute;
    top: 150px;
  }
`;
const RightButton = styled(Buttons)`
  right: 50px;
  @media(max-width: 800px){
    right: unset;
    left: 0px;
  }
`;
const LeftButton = styled(Buttons)`
  left: 50px;
  @media(max-width: 800px){
    left: unset;
    right:0px;
  }
`;
const ButtonsWrapper = styled.div`
  @media(max-width: 800px){
    position: absolute;
  }
`;
function getDescriptionByCategory(category: string){
  if(category.toLowerCase() === 'art'){
    return 'painter';
  } else if(category.toLowerCase() === 'anime'){
    return 'anime';
  } else if(category.toLowerCase() === 'fiction'){
    return 'fictional character';
  }else if(category.toLowerCase() === 'fashion'){
    return 'brand';
  }
  return '';
}

const Carousel: FC<CarouselProps> = () => {    
  const {categories, getCategoryByName, effects} = useEffectsDataContext();

  const carouselRef = useRef<CarouselRef | null>(null);



  const data: DecoratedCarouselItem[] = useMemo(
    () =>
      effects.map(item => ({
        ...item,
        id: item.effect_id
      })),
    [effects]
  );



  const len = useMemo(() => data.length, [data.length]);
  const theta = useMemo(() => 360 / len, [len]);

  const calculateRadius = (len: any, isMobile: any) =>
  Math.round((isMobile ? 160 : 370) / 2 / Math.tan(Math.PI / len));

  const radius = useMemo(() => calculateRadius(len, isMobile), [len, isMobile]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const next = useCallback(
    () => setSelectedIndex(selectedIndex + 1),
    [selectedIndex]
  );
  
  const prev = useCallback(
    () => setSelectedIndex(selectedIndex - 1),
    [selectedIndex]
  );

  

  useEffect(() => {
    const area = document.getElementById('carousel'); 
    area?.addEventListener('swipeleft', () => setSelectedIndex(selectedIndex + 1));
    area?.addEventListener('swiperight', () => setSelectedIndex(selectedIndex - 1));

    return () => {
      area?.removeEventListener('swipeleft', () => setSelectedIndex(selectedIndex + 1));
      area?.removeEventListener('swiperight', () => setSelectedIndex(selectedIndex - 1));
    };
  }, [selectedIndex]);


  useImperativeHandle(
    {} as React.RefObject<CarouselRef>, // No ref forwarding
    (): CarouselRef => ({
      next: () => setSelectedIndex(selectedIndex + 1),
      prev: () => setSelectedIndex(selectedIndex - 1),
      getItems: () => data,
      getSelectedIndex: () => selectedIndex,
      setSelectedIndex: (index: number) => setSelectedIndex(index)
    })
  );

  const touchRef = useRef({
    startX: 0,
    startY: 0,
  });

  const handleTouchStart = (event: TouchEvent) => {
    touchRef.current.startX = event.touches[0].clientX;
    touchRef.current.startY = event.touches[0].clientY;
  };

  const handleTouchEnd = (event: TouchEvent) => {
    const deltaX = event.changedTouches[0].clientX - touchRef.current.startX;
    const deltaY = event.changedTouches[0].clientY - touchRef.current.startY;

    // You can adjust the threshold as needed
    const threshold = 50;

    if (Math.abs(deltaX) > threshold) {
      if (deltaX > 0) {
        // Swipe right
        setSelectedIndex((prevIndex) => (prevIndex - 1 + len) % len);
      } else {
        // Swipe left
        setSelectedIndex((prevIndex) => (prevIndex + 1) % len);
      }
    }
  };

  const {addEffect, setEffectName, setEffectImage } = useCreationProcessContextValue();
  let navigate = useNavigate();

  const handleSlideClick = (effectId: string, effectName: string, effectImage: string) =>{
    console.log(effectId)
    addEffect(effectId);
    setEffectName(effectName);
    setEffectImage(effectImage);
    navigate('/create');
  }


  useEffect(() => {
    const carouselContainer = document.getElementById('carousel');

    carouselContainer?.addEventListener('touchstart', handleTouchStart);
    carouselContainer?.addEventListener('touchend', handleTouchEnd);

    return () => {
      carouselContainer?.removeEventListener('touchstart', handleTouchStart);
      carouselContainer?.removeEventListener('touchend', handleTouchEnd);
    };
  }, [selectedIndex]);

  useEffect(() => {
    let counter = 0; 
    const timer = setInterval(() => {
      if (selectedIndex < len - 1 && counter < 4) { 
        setSelectedIndex(prevIndex => prevIndex + 1);
        counter++; 
      }
    }, 3000);
  
    setTimeout(() => {
      clearInterval(timer);
    }, 12000);
  
    return () => clearInterval(timer);
  }, [selectedIndex, len]);


  return (
    <Wrapper>

      <CarouselWrapper id = 'carousel'>
        <CarouselContainer 
        style={{ transform: `translateZ(${-1 * radius}px) rotateY(${theta * selectedIndex * -1}deg)` }}>
          {data.map((item: DecoratedCarouselItem, index: number) => (
            <Slide
              key={item.id}
              index={index}
              len={len}
              theta={theta}
              radius={radius}
              selectedIndex={selectedIndex}
              onClick={() => handleSlideClick(item.effect_id, item.effect_name, item.effect_image_url)}
            >
              <SlideImage src={item.effect_image_url} alt={item.effect_name} />
              <EffectName>{item.effect_name}</EffectName>
              <EffectInspiration>inspired by the famous {getDescriptionByCategory(item.effect_category)}</EffectInspiration>
            </Slide>
          ))}
        </CarouselContainer>
      </CarouselWrapper>
      
    <ButtonsWrapper>
      <LeftButton onClick={prev}><ArrowBackIosIcon style={{ fontSize: '44px' }}/></LeftButton>
      <RightButton onClick={next}><ArrowForwardIosIcon style={{ fontSize: '44px' }}/></RightButton>
    </ButtonsWrapper>
    </Wrapper>
  );
};

export default Carousel;