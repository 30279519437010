// Image.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import ImageOverlay from './ImageOverlay';

interface ImageProps {
  src: string;
}

const ImageStyles = styled.img`
  cursor: pointer;
  border-radius: 10%;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
  &:hover {
    background-color: grey;
  }
`;

const Image: React.FC<ImageProps> = ({ src }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handleImageClick = () => {
    console.log('clicked')
    setIsOverlayOpen(true);
  };

  return (
    <>
      <ImageStyles src={src} onClick={handleImageClick} />
    </>
  );
};

export default Image;
