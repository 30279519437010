import React, {useState, useEffect} from 'react';
import styled  from 'styled-components';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


interface LogInPageBackgroundProps {
    children: React.ReactNode;
  }

const Wrapper = styled.div`
    background-color:#008F95;
    width:100vw;
    height:96vh;
    position: relative;
    overflow: hidden;
    text-align:center;
`;
const Background = styled.div`
    width:100vw;
    position:absolute;
    z-index:0;
    display:flex;
    flex-direction: row;
    overflow: hidden;
    text-align:center;
    justify-content:space-between;
    margin: 10px;
    margin-top:-60px;
    @media(max-width: 700px){
      margin-top: 10px;
    }

`;
const ImageCard = styled.img`
    position:relative;
    border-radius: 10%;
    width:80%;
    height:40vh;
    margin-bottom: 30px;
    object-fit: cover;
    @media(max-width: 700px){
        height:20vh;
        margin-bottom:10px;

    }
`;

const StyledImageList = styled(ImageList)`
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: auto;
` ;
const StyledImageListItem = styled(ImageListItem)`
    width: 100%;
`;


const LogInPageBackground :React.FC <LogInPageBackgroundProps> = ({children}) =>{

  const [columns, setColumns] = useState<number>(5); // Default value for desktop

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setColumns(3); // Adjust the threshold (600 in this case) based on your design
      } else {
        setColumns(5);
      }
    };

    // Initial check
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

    const imageNames = [
        'agreeable_hospital_as_1980s_balenciaga_male_model_on_the_runway_93f8589a-d7d3-44a6-a01a-13180a18bde0_ins.jpg',
        'agreeable_hospital_boho_chic_2e961e1e-3943-48ad-8bbd-e464df6d6525_ins.jpg',
        'agreeable_hospital_boho_chic_98c43a13-6366-4e07-ab23-ed6463f13230_ins.jpg',
        'agreeable_hospital_french_new_wave_f483466a-f3f0-40a6-8846-be3d2c0b56ea_ins.jpg',
        'banksy-for-arts1.jpg',
        'banksy-for-arts2.jpg',
        'Dior-female2.jpg',
        'initialized_swapped_1_elsa.jpg',
        'initialized_swapped_1.jpg',
        'initialized_swapped_harry.jpg',
        'initialized_swapped.jpg',
        'initialized_swapped_superwoman.jpg',
        'pure prompt.jpg',
        'young_luke.jpg',
        'WesAnderson.jpg',
      ];


    return(
        <Wrapper>
            <Background>
            <StyledImageList  cols={columns} >
                {imageNames.map((item) => (
                    <StyledImageListItem key={item}>
                        <ImageCard
                            srcSet={require(`../../assets/LoginPageImages/${item}`)}
                            src={require(`../../assets/LoginPageImages/${item}`)}
                            alt={item}
                            loading="lazy"
                        />
                    </StyledImageListItem>
                ))}
            </StyledImageList>
            </Background>
            {children}
        </Wrapper>
    )

}
export default LogInPageBackground;