import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useUser } from '../../Hooks/Context/UserContext';
import {API_URL} from '../../urls';

interface CreationProcessContextValue {
  hasStarted: boolean;
  setStarted: () => void;
  selectedImage: string | File | null; 
  addImage: (imageData: File) => void;
  selectedEffectId: string;
  addEffect: (effectId: string) => void; 
  hasCompletedAnImage: boolean;
  completedCreation:  () => void;
  effectName: string;
  setEffectName: (effectName: string) => void;
  imageId: string | null;
  setImageId: React.Dispatch<React.SetStateAction<string | null>>;
  loading: boolean;
  effectImage: string | null;
  setEffectImage: (effectImage: string) => void;
  age: string;
  setAge: (age: string) => void;
  gender: string;
  setGender: (gender: string) => void;
}

// Define the props type for CreationProcessProvider
interface CreationProcessProviderProps {
  children: ReactNode;
}

const CreationProcessContext = createContext<CreationProcessContextValue | undefined>(undefined);

// CreationProcessProvider component
const CreationProcessProvider: React.FC<CreationProcessProviderProps> = ({ children }) => {
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | File >('');
  const [selectedEffectId, setSelectedEffectId] = useState<string>('');
  const [hasCompletedAnImage, setHasCompletedAnImage] = useState<boolean>(false);
  const [effectName, setSelectedEffectName] = useState<string>('');
  const [imageId, setImageId] = useState<string | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [effectImage, setSelectedEffectImage] = useState<string | null>(null);
  const [age, setAge] = useState<string>(''); 
  const [gender, setGender] = useState<string>('');

  const setStarted = () => setHasStarted(true);
  const { token } = useUser();

  useEffect(() => { 
    if(token){
    setStarted();
    setIsLoading(true);
    // Define the URL for the Flask backend route
    const url = `${API_URL}/get_user_main_image?token=${encodeURIComponent(token)}`;

    // Make the fetch request
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setIsLoading(false);
        // Handle the response data
        if(data != null && data!=''){
          setSelectedImage(data);
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
    }
  }, [token]);


  const addImage = (imageData: File) => {   
    setStarted();
    setIsLoading(true);

    const formData = new FormData();
        formData.append('file', imageData);
        formData.append('token', token);
      
        fetch(API_URL+'/upload_user_image', {
          method: 'POST',
          body: formData,
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok uploading the image');
          }

          const theResponse = response.json();
          return theResponse; // If the server sends a JSON response
        })
        .then(data => {
            setIsLoading(false);
            
            setSelectedImage(data.image_url);
          return true;
        })
        .catch(error => {
          console.error('Error:', error);
        });

  };

  const addEffect = (effectId: string) => {
    setSelectedEffectId(effectId); 
    setStarted();
  };
  const setEffectName = (effectName: string)=>{
    setSelectedEffectName(effectName);
  }
  const setEffectImage = (effectImage: string) => {
    console.log('effectImage: ', effectImage)
    setSelectedEffectImage(effectImage);
  }
  const completedCreation = () =>{

    const formData = new FormData();
        formData.append('file', selectedImage.toString());
        formData.append('token', token);
        formData.append('effect_requested', selectedEffectId);
        formData.append('age', age);
        formData.append('gender', gender);
        fetch(API_URL+'/request_ai_generated_image', {
          method: 'POST',
          body: formData,
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok requesting a product image');
          }
          return response.json(); // If the server sends a JSON response
        })
        .then(data => {
          setImageId(data.image_id); 
        })
        .catch(error => {
          console.error('Error:', error);
        });

    setHasCompletedAnImage(true);

  }
   
  const value = {
    setEffectImage,
    effectImage,
    setEffectName,
    effectName,
    hasStarted,
    setStarted,
    selectedImage,
    addImage,
    selectedEffectId,
    addEffect,
    hasCompletedAnImage,
    completedCreation,
    imageId,
    setImageId,
    loading,
    age, 
    setAge, 
    gender, 
    setGender, 
  };

  return <CreationProcessContext.Provider value={value}>{children}</CreationProcessContext.Provider>;
};

const useCreationProcessContextValue = (): CreationProcessContextValue => { 
  const context = useContext(CreationProcessContext);

  if (context === undefined) {
    throw new Error('useCreationProcessContextValue must be used within a CreationProcessProvider');
  }
  return context;
};

export { CreationProcessProvider, useCreationProcessContextValue }; 
