import React, {useState, useEffect} from 'react';
import { useUser } from '../../../Hooks/Context/UserContext';
import Image1 from '../../../assets/Sample Images/image1.jpeg';
import Image2 from '../../../assets/Sample Images/image2.jpeg';
import Image3 from '../../../assets/Sample Images/image3.jpeg';
import Image4 from '../../../assets/Sample Images/image4.jpeg';
import styled from 'styled-components';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Image from './Image';
import Carousel from './Carousel';
import ImageOverlay from './ImageOverlay';
import useFetch from '../../../Hooks/useFetch';
import {API_URL} from '../../../urls';
import {NavigationLink} from '../../NavigationLink';
import InProgressImage from './InProgressImage';
import CircularProgress from '@mui/material/CircularProgress';
import CardStack from './CardStack';
import CardStackOverlay from './CardStackCompontents/CardStackOverlay';
import FailedToGenerate from './FailedToGenerate';
import moment from 'moment-timezone';
import Footer from '../../Footer/Footer';

interface GalleryTabProps {
  isCommunityTab?: boolean;
  isMyImagesTab?: boolean;
}
type Image = {
  effect_used_id: string;
  image_id: string;
  image_url: any;
  status: string;
  user_id: string;
  original_image_url: any;
  created_at: string;
}
const Wrapper = styled.div`
  text-align:center;
`;
const ImageStyled = styled.img`
  cursor: pointer;
  border-radius: 10%;
  width: 100%;
  margin-bottom: 30px;
  background-color:lightgrey;
  color: lightgrey;
  @media (max-width: 700px) {
    margin-bottom: 10px;
  }
  &:hover {
    background-color: grey;
  }
`;

const NoImageText = styled.h1`
  color:#480CA8;
  text-align:center;
  margin: 20px 30%;
  @media(max-wdith:700px){
    margin: 0px 10% ;
  }
`;

const StyledImageListItem = styled(ImageListItem)`
  width: 100%;
  height: auto;
  @media( min-width: 700px){
    margin: 10px 0px;
    margin-top: 0px;
    width: 75%;
  }
  @media(min-width: 1200px){
    width: 100%;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  align-self:center;
  margin:10vh 45vw;
`;
const StyledImageList = styled(ImageList)`
  padding-top:20px;
  overflow: hidden !important;

`;

const GalleryTab: React.FC<GalleryTabProps> = ({ isCommunityTab, isMyImagesTab }) => {

  const imageNames = [
    'agreeable_hospital_as_1980s_balenciaga_male_model_on_the_runway_93f8589a-d7d3-44a6-a01a-13180a18bde0_ins.jpg',
    'agreeable_hospital_boho_chic_2e961e1e-3943-48ad-8bbd-e464df6d6525_ins.jpg',
    'agreeable_hospital_boho_chic_98c43a13-6366-4e07-ab23-ed6463f13230_ins.jpg',
    'agreeable_hospital_french_new_wave_f483466a-f3f0-40a6-8846-be3d2c0b56ea_ins.jpg',
    'banksy-for-arts1.jpg',
    'banksy-for-arts2.jpg',
    'Dior-female2.jpg',
    'initialized_swapped_1_elsa.jpg',
    'initialized_swapped_1.jpg',
    'initialized_swapped_harry.jpg',
    'initialized_swapped.jpg',
    'initialized_swapped_superwoman.jpg',
    'pure prompt.jpg',
    'young_luke.jpg',
    'WesAnderson.jpg',
  ];

  const CardStackLinks = [
    'https://ai-filter-images.s3.amazonaws.com/users/f8e70d2e-9673-4d86-ad6a-3ac06853721f_0f08dfc2-4047-4da5-91ac-ef84b5fa3dc1download (1).jpeg',
    'https://ai-filter-images.s3.amazonaws.com/users/106107280687336507074_e17aa1b4-5aeb-421b-bc87-9066f95c65b2AhmadElAmineImage.jpeg',
    'https://ai-filter-images.s3.amazonaws.com/effects/Category+-+Fashion/Bohemian/agreeable_hospital_boho_chic_2e961e1e-3943-48ad-8bbd-e464df6d6525_ins.jpg'
  ]

  const [columns, setColumns] = useState<number>(4);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [lengthOfArray, setLengthOfArray] = useState<number>( imageNames.length);
  const [userImage, setUserImage] = useState<Image[]> ([]);
  const {token} = useUser();
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [ClickedCardStack, setClickedCardStack] = useState<any>('');
  const [effect_used, setEffectUsed] = useState<string>('')


  const [isCardStackOverlayOpen, setisCardStackOverlayOpen] =useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setColumns(2);
      } else {
        setColumns(4);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedImageIndex != null) {
      if (isMyImagesTab) {
        if (userImage[selectedImageIndex]?.image_url) {
          setSelectedImage(userImage[selectedImageIndex].image_url);
        }
      } else {
        setSelectedImage(require(`../../../assets/LoginPageImages/${imageNames[selectedImageIndex]}`));
      }
    }
  }, [selectedImageIndex]);
  

  const handleImageClick = (imageName: string, index: number) => {
    setSelectedImage(imageName);
    setIsOverlayOpen(true);
    setSelectedImageIndex(index);
  };
  const handleCardStackClick = (images: any,effect_used: string) =>{
    setisCardStackOverlayOpen(true);
    setClickedCardStack(images);
    setEffectUsed(effect_used);
  }



  const handlePreviousClick = ()=>{
    if(selectedImageIndex != null){
      console.log(selectedImageIndex);
      if(selectedImageIndex === 0){
        setSelectedImageIndex(lengthOfArray-1);
      }else{
        setSelectedImageIndex((selectedImageIndex-1));

      }
    }
  }
  const handleNextClick = () => {
    if(selectedImageIndex != null){
      console.log(selectedImageIndex);
      if(selectedImageIndex === lengthOfArray-1){
        setSelectedImageIndex(0);
      }else{
        setSelectedImageIndex((selectedImageIndex+1));
      }
    }
  }
  const isTimestampMoreThan5MinutesAgo = (timestamp: string): boolean => {
    const currentTimestamp = new Date();
    const providedTimestamp = new Date(timestamp);
  
    // Calculate the time difference in milliseconds
    const timeDifference = currentTimestamp.getTime() - providedTimestamp.getTime();
  
    // Convert milliseconds to minutes
    const minutesDifference = timeDifference / (1000 * 60);
  
    // Check if the time difference is greater than 5 minutes
    return minutesDifference > 5;
  };
  useEffect(() => {
    if (isMyImagesTab && token != null && token !== '') {
      setLoading(true); // Set loading to true when fetch starts
      const url = `${API_URL}/get_all_generated_images_by_userid?token=${encodeURIComponent(token)}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          const sortedData = data.sort((a:any, b:any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

          setUserImage(sortedData);
        })
        .catch(error => console.error('Error:', error))
        .finally(() => {
          setLoading(false); // Set loading to false when fetch completes (either success or error)
        });
    }
  }, [isMyImagesTab, token, updateTrigger]);

  
  const triggerUpdate = () => {
    setUpdateTrigger(prev => prev + 1);
  };




  return (
    <Wrapper>

        <>
            {isMyImagesTab ? (
              <div id='myImages'>
              {loading ? ( 
                <StyledCircularProgress size={100} disableShrink color="secondary" />
              ) : userImage.length === 0 ? (
                <div>
                  <NoImageText>You haven't made any images.<br/> Click on the button to generate some amazing pictures of you.</NoImageText>
                  <NavigationLink variant='primary' to='/create'> Let's create</NavigationLink>
                </div>
              ) : (
                  
              <StyledImageList variant="standard" cols={columns}>
                  {userImage.map((item, index) => (
                    <div key={index}>
                    {(item.image_url !== '' || item.image_url === null) ? (
                          <StyledImageListItem 
                          onClick={() => handleCardStackClick(JSON.parse(item.image_url),item.effect_used_id)}>
                            <CardStack
                              imagesArray={JSON.parse(item.image_url)}
                              effect_used={item.effect_used_id}
                            ></CardStack>
                          </StyledImageListItem>
                                                    
                        ) : (isTimestampMoreThan5MinutesAgo(item.created_at) || !item.created_at || item.status==='failed')?(
                          <StyledImageListItem >
                            <FailedToGenerate/>
                          </StyledImageListItem>
                        )
                        :
                        (
                          <StyledImageListItem  >
                            <InProgressImage triggerUpdate={triggerUpdate} original_image={item.original_image_url} image_id={item.image_id} effect_used={item.effect_used_id} />
                          </StyledImageListItem>

                        )}
                    </div>    
                ))}
                </StyledImageList>
                  
                )}
              </div>
            ) : (
              <StyledImageList variant="woven" cols={columns}>
                  {imageNames.map((item, index) => (
                    <ImageListItem key={index}>
                      <ImageStyled
                        key={index}
                        src={require(`../../../assets/LoginPageImages/${item}`)}
                        onClick={() => handleImageClick(require(`../../../assets/LoginPageImages/${item}`), index)}
                      />
                    </ImageListItem>

                  ))}

               </StyledImageList>

            )}
            {isCardStackOverlayOpen && <CardStackOverlay images={ClickedCardStack} onClose={() => setisCardStackOverlayOpen(false)} effect_id={effect_used}/>}
            
            {isOverlayOpen && <ImageOverlay src={selectedImage} onClose={() => setIsOverlayOpen(false)} previousClick={handlePreviousClick} nextClick={handleNextClick} />}
          
        </>
     
    </Wrapper>
  )
}

export default GalleryTab;

