import React, { useEffect, useState } from 'react';
import { BrowserView, isMobile } from 'react-device-detect';
import { NavigationLink } from '../NavigationLink';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { useUser } from '../../Hooks/Context/UserContext';
import LogInOrAccount from '../LogIn/LoginOrAccount';
import { useLocation } from 'react-router-dom';

import BackgroundImage from '../../assets/white-concrete-wall.jpg';

var beforeLogoItems = [
  { name: 'Gallery', path: '/gallery' }
];

var afterLogoItems = [
  { name: 'Create', path: '/create' },
];

var logoItem = { name: 'Generate-Me', path: '/' };
interface WrapperProps{
  $isLoggedIn: string;
}
const Wrapper = styled.div<WrapperProps>`
  width:100%;
  display: flex;
  justify-content: right;
  align-items: center;
  height: 80px;
  position:sticky;
  z-index: 1000;
  @media(max-width:800px){
    justify-content: ${props => props.$isLoggedIn === 'true'? 'center' : 'flex-end'};
    padding-left: ${props => props.$isLoggedIn === 'true'? '15vw' : '5vw'};
    top:0px;
    z-index:1000;
  }
  @media(min-width: 800px){
    padding-right: 5vw;
  }
`;



const InnerWrapper = styled.div`
  display: flex;
  padding: 0px 10%;
  @media(max-width:800px){
    justify-content: center;
    align-items: center;
    width:100%;
    height: 100%;
  }
`
const StyledBrowserView = styled(BrowserView)`
  margin-top: 6px;
  padding: 0px;
`;

const NavigationItems = styled.div`
  display: flex;
  @media(max-width: 800px){
    margin: auto;
    margin-left: 30%;
  }
`;
const Logo = styled(NavigationLink)`
  left: 200px;
  position:absolute;
  background-color: unset;
  font-size: 40px;
  @media( max-width: 800px){
    font-size: 1.8rem;
    left:0px;
    background-color: unset;
  }
`;

const StyledNavigationLInk = styled(NavigationLink)`
    @media(max-width: 800px){
      margin-left: 35px !important;
    }
`;

const NavigationBar = () => {
  const { t } = useTranslation();
  const { token } = useUser();
  const [isLoggedIn, setIsLoggedIn] = useState(token);
  const location = useLocation();

  let urlContainsCreate = location.pathname.includes('create');
  let modifiedAfterLogoItems = urlContainsCreate && window.innerWidth < 700
    ? [{ name: 'Gallery', path: '/gallery' }]
    : afterLogoItems;
  

  useEffect(() => {  
     urlContainsCreate = location.pathname.includes('create');
   modifiedAfterLogoItems = urlContainsCreate && window.innerWidth < 700
    ? [{ name: 'Gallery', path: '/gallery' }]
    : afterLogoItems;
  }, [location]);

  useEffect(()=>{
    setIsLoggedIn(token);
  },[token])
  
  return (
    <Wrapper $isLoggedIn = {isLoggedIn? 'true' : 'false'}>
      <InnerWrapper>
      <Logo variant="logo" to={logoItem.path}>
        {logoItem.name}
      </Logo>
      <NavigationItems>
        {beforeLogoItems.map((item, index) => (
          <StyledBrowserView key={index}>
            <StyledNavigationLInk variant="navigation" to={item.path}>
              {t('navigation.'+ item.name)}
            </StyledNavigationLInk>
          </StyledBrowserView>
        ))}
      
        {modifiedAfterLogoItems.map((item, index) => (
          <StyledNavigationLInk key={index} variant="navigation" to={item.path}>
            {t('navigation.'+ item.name)}
          </StyledNavigationLInk>
        ))}
      </NavigationItems>
      <LogInOrAccount/>
      </InnerWrapper>
    </Wrapper>
  );
};

export default NavigationBar;
