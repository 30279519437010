import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FacebookShareButton, TwitterShareButton, PinterestShareButton, RedditShareButton } from 'react-share';
import { EffectsDataContextProvider, useEffectsDataContext } from '../../../../Hooks/Context/EffectsDataContext';

import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { RiTwitterXFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa";
import { saveAs } from 'file-saver';
import FacebookGraphButton from './FacebookGraphButton';




interface ShareButtonsProps {
    images: string[];
    effect_id: string;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;

`;
const Header = styled.h4`
    font-size: 20px;

    @media(max-width: 800px){
        font-size: 15px;
    }
    @media(min-width: 1150px){
        font-size: 30px;
    }
`;

const FacebookStyled = styled(TiSocialFacebook)`
    cursor: pointer;
    @media(max-width: 1100px){
        width: 100px;
        height: 30px;
        margin-right: 20px;
    }
    @media(max-width: 800px){
        height: 28px;
    }
    @media(min-width: 1150px){
        width: 100px;
        height: 50px;
        margin-bottom: 20px;
    }
`;
const InstagramStyled = styled(TiSocialInstagram)`
    cursor: pointer;
    @media(max-width: 1100px){
        width: 70px;
        height: 30px;
        margin-right: 20px;
    }

    @media(max-width: 800px){
        height: 28px;
    }
    @media(min-width: 1150px){
        width: 100px;
        height: 50px;
        margin-bottom: 20px;
    }
`;
const XStyled = styled(RiTwitterXFill)`
    cursor: pointer;
    @media(max-width: 1100px){
        width: 70px;
        height: 30px;
        margin-right: 20px;
    }
    @media(max-width: 800px){
        height: 28px;
    }
    @media(min-width: 1150px){
        width: 100px;
        height: 50px;
        margin-bottom: 20px;
    }
`;

const DownloadIconStyled = styled(FaDownload)`
    cursor: pointer;
    @media(max-width: 1100px){
        width: 70px;
        height: 30px;
        margin-right: 20px;
    }
    @media(max-width: 800px){
        height: 28px;
    }
    @media(min-width: 1150px){
        width: 100px;
        height: 50px;
        margin-bottom: 20px;
    }
`;

const IconsContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    @media(max-width: 1050px){
       width: 90%;
    }
    @media(min-width: 1150px){
        flex-direction: column;
    }
    @media(max-width: 1100px){
        margin-left: 20px;
    }
`;


const ShareButtons: FC<ShareButtonsProps> = ({images, effect_id}) => {
    const { getEffectNameById } = useEffectsDataContext();
    const effectName = getEffectNameById(effect_id);
    const facebookHashtags = ['hashtag1', 'hashtag2', 'hashtag3'];
    
    const xHashtags = ['hashtag1', 'hashtag2', 'hashtag3']; 
    
    const instagramHastags = ['hashtag1', 'hashtag2', 'hashtag3'];
    
    const pinterestHastags = ['hashtag1', 'hashtag2', 'hashtag3'];

    const shareableURL = images.join(',');

    const generateShareMessage = (effectName:string | undefined, index: number, hashtags: string[]) => {
        const tags = hashtags.map(tag => `#${tag}`).join(' ');
        return `${effectName} ${index + 1} ${tags}`;
    };
    

    const combinedMessage = images
    .map((image, index) =>
      generateShareMessage(effectName, index, facebookHashtags)
    )
    .join('\n');

    const downloadImage = async (imageUrl: string, fileName: string) => {
        try {
          const response = await fetch(imageUrl, {
            method: 'GET'
          });
      
          if (!response.ok) {
            throw new Error('Failed to download image');
          }
      
          // Convert the image to blob
            
          const imageBlob = await response.blob();
          console.log(imageBlob);
          // Create an object URL from the blob
          const objectUrl = URL.createObjectURL(imageBlob);
            // Create an anchor element
            const downloadLink = document.createElement('a');

            // Set href attribute of the anchor to the URL of the blob
            downloadLink.href = objectUrl;

            // Set the filename for the downloaded file
            downloadLink.setAttribute('download', `${fileName}.jpg`);

            // Append the anchor to the body
            document.body.appendChild(downloadLink);

            // Click the anchor to initiate the download
            downloadLink.click();

            // Clean up: remove the anchor element and revoke the object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(objectUrl);
                
          // Return the object URL
          return objectUrl;
        } catch (error) {
          console.error('Error downloading image:', error);
          return null;
        }
    }

    const handleDownload = async () => {

        images.map((image,index)=>{
            downloadImage(images[index],effectName+(index+'') || '');
        })
    };
    

    return(
        <Wrapper>
            <IconsContainer>
                <DownloadIconStyled size={20} onClick={handleDownload} />
                <FacebookStyled size={20}/>
                <InstagramStyled size={20}/>
                <XStyled size={20}/>
            </IconsContainer>
      </Wrapper>
    )
}
export default ShareButtons;