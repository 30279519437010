import React from 'react';
import { Route, Routes} from 'react-router-dom';
//Imports of the pages
import LandingPage from '../LandingPage/LandingPage';
import Gallery from '../Gallery/Gallery';
import OurEffects from '../OurEffects/OurEffects';
import Create from '../Create/Create';
import Account from '../Account/Account';
import PremiumPage from '../Payment/PremiumPage';
import VerificationPage from '../VerificationPage/VerificationPage';
import ResetPassword from '../ResetPassword/ResetPassword';
import Finished from '../Create/CreateComponents/Finished';


const WebsiteRouter = () => (
  
    <Routes>
      <Route  path="/" element={<LandingPage/>}/>
      <Route  path="/gallery" element={<Gallery/>}/>
      <Route  path="/ourEffects" element={<OurEffects/>}/>
      <Route  path="/create" element={<Create/>}/>
      <Route  path="/account" element={<Account/>}/>
      <Route  path='/PremiumPage' element = {<PremiumPage/>}/>
      <Route path="/verificationPage" element={<VerificationPage />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="*" element={<div>Page not found!</div>} />
      <Route
          path="/finished"
          element={<Finished  />}
        />
    </Routes>
);

export default WebsiteRouter;
