import React, { createContext, useContext, useState, ReactNode } from 'react';

interface CurrentEffectGroupContextValue {
  currentEffectGroupName: string;
  changeName: (newName: string) => void;
  getName: () => string;
  getCurrentEffectGroupColor: () => string;
  getEffectGroupColorByName: (name: string) => string;
}

const CurrentEffectGroupContext = createContext<CurrentEffectGroupContextValue | undefined>(undefined);

// Define the props type for CurrentEffectGroupProvider
interface CurrentEffectGroupProviderProps {
  children: ReactNode;
}

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// CurrentEffectGroupProvider component
const CurrentEffectGroupProvider: React.FC<CurrentEffectGroupProviderProps> = ({ children }) => {
  const [name, setName] = useState<string>('Fiction');

  const changeName = (new_name: string) => setName(new_name);
  const getName = () => name;

  const effectGroupNames: { [key: string]: string } = {
    Fashion: 'gold',
    Anime: '#008086',
    Art: '#c7fcec',
    Fiction: '#7E2D00',
  };
  
  const getCurrentEffectGroupColor = (): string => {
    const currentName = getName();
    if (effectGroupNames[currentName]) {
      return effectGroupNames[currentName];
    } else {
      return getRandomColor();
    }
  };
  const getEffectGroupColorByName =(name: string): string=>{
    if (effectGroupNames[name]) {
      return effectGroupNames[name];
    } else {
      return getRandomColor();
    }
  }

  const value = { currentEffectGroupName: name, changeName, getName, getCurrentEffectGroupColor, getEffectGroupColorByName };


  return (
    <CurrentEffectGroupContext.Provider value={value}>
      {children}
    </CurrentEffectGroupContext.Provider>
  );
};

const useCurrentEffectGroupContext = (): CurrentEffectGroupContextValue => {
  const context = useContext(CurrentEffectGroupContext);
  
  if (context === undefined) {
    throw new Error('useCurrentEffectGroupContext must be used within a CurrentEffectGroupProvider');
  }
  
  return context;
};

export { CurrentEffectGroupProvider, useCurrentEffectGroupContext };
