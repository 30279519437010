import React, { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import ShareButtons from './ShareButtons';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { keyframes } from 'styled-components';



interface CardStackOverlayProps {
    images: string[];
    onClose: () => void;
    effect_id: string;
}

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ImageWrapper = styled(Grid)`
    border-radius: 20px;
    height: 100%;
    width: 75% !important;
    padding: 10px;
    @media(max-width: 1050px){
        width: 85% !important;
    }
    @media(max-width: 800px){
        background: unset; 
        width: 100% !important;
        padding: 5px;
        padding-top: 20px;
        border: 0px solid lightgrey;
    }
`;
const Image = styled.img`
    cursor: pointer;
    width: 98%;
    aspect-ratio: 1/1;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
    @media(max-width: 800px){
        width: 100%;
        aspect-ratio: 1/1.1;
    }
`;
const StyledButton = styled.button`
    background-color: unset;
    color: white;
    position: absolute;
    right: 10vw;
    top: 20px;
    cursor: pointer;
    font-size: 50px;
    border-color: unset;
    border-radius: 10px;
    @media(max-width:800px){
      right: 3vw;
      top: 30px;
      font-size: 24px;
      border-radius: 10px;
    }
`;
const MiddleSection = styled.div`
    position: absolute;
    top:40%;
    right:55%;
    background-color: red;
`;
const ContentWrapper = styled.div`
    border-radius: 20px;
    background: rgba(255,255,255, 0.6); 
    width: 60%;
    max-height: 90%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;; 
    justify-content: center;
    @media(max-width: 1050px){
        flex-direction: column;
    }
    
    @media(max-width: 800px){
        flex-direction: column;
        width: 90%;
        padding: 15px;
        height: 70%;
    }
`;
const TextAndShareSection = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.8);
    border-radius: 20px;
    height: inherit;
    width: 25%;
    @media(max-width: 1050px){
        height: 100px;
        width: 100%;
        background-color: unset;
        border: 1px solid grey;
    }
    @media(max-width: 800px){
        height: 100px;
        width: 100%;
    }
`;
const ShareButtonsWrapper = styled.div`
    font-size: 1.4rem;
    @media (max-width: 800px){
        font-size: 1.2rem;

    }
`;
const EnlargedImage = styled.img`
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: inherit;
    @media(max-width: 800px){
        height: unset;
        width: 105%;
    }
`;
const ZoomOutIconStyled = styled(ZoomOutIcon)`

    && {
        z-index: 2;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right:20px;
        font-size: 50px;
        color: white;
        @media(max-width: 800px){
            top: 20%;
            right:20px;
        }
    }
`;
const fadeIn = keyframes`
  from {
    width: 0;
    height: 0;
  }

  to {
    width: 100%;
    height: 100%;
  }
`;
const InlargedImageWrapper = styled.div`
  animation: ${fadeIn} 0.2s forwards;
  z-index: 4000;
  border-radius: 20px;
  background: rgba(0,0,0, 0.9); 
  width: 60%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  @media(max-width: 1050px){
      flex-direction: column;
  }
  
  @media(max-width: 800px){
      flex-direction: column;
      width: 90%;
  }
`;

const CardStackOverlay: FC<CardStackOverlayProps> = ({images, onClose, effect_id}) => {
    const [clickedImage, setClickedImage] = useState('');
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);
    return(
        <Wrapper>
            {(clickedImage === '')?(
                                <ContentWrapper ref={wrapperRef}>
                                <ImageWrapper container spacing={1}>
                                    {images.map((item, index) => (
                                        <Grid item xs={6} sm={6} key={index}>
                                            <Image src={item} loading="lazy" onClick={()=>setClickedImage(item)}/>
                                        </Grid>
                                    ))}
                                </ImageWrapper>
                                
                                <TextAndShareSection>
                                    <ShareButtonsWrapper>
                                        <ShareButtons images={images} effect_id={effect_id}/>
                                    </ShareButtonsWrapper>
                                </TextAndShareSection>
                                <StyledButton onClick={onClose}>X</StyledButton>
                            </ContentWrapper>
            ):(   
                <InlargedImageWrapper >
                    <EnlargedImage src={clickedImage}></EnlargedImage>
                    <ZoomOutIconStyled
                        onClick={()=>setClickedImage('')}/>
                </InlargedImageWrapper>
            )}
        </Wrapper>
    );
}

export default CardStackOverlay;