import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PopupContextValue {
  isopen: boolean;
  openPopup: () => void;
  closePopup: () => void;
}

const PopupContext = createContext<PopupContextValue | undefined>(undefined);

// Define the props type for PopupProvider
interface PopupProviderProps {
  children: ReactNode;
}

// PopupProvider component
const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [isopen, setIsOpen] = useState<boolean>(false);

  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);
  
  const value = { isopen, openPopup, closePopup };

  return <PopupContext.Provider value={value}>{children}</PopupContext.Provider>;
};

const usePopupContext = (): PopupContextValue => {
  const context = useContext(PopupContext);
  
  if (context === undefined) {
    throw new Error('usePopupContext must be used within a PopupProvider');
  }
  
  return context;
};

export { PopupProvider, usePopupContext };
