import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {SectionLayout,StyledValue,NameOfComponent, Content} from './ContentStyles';
import { API_URL } from '../../urls';
import {useUser} from '../../Hooks/Context/UserContext';
import UnsubscribeModal from './UnsubscribeModal';
import ChangePasswordModal from './ChangePasswordModal';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  text-align:center;
  margin:20px;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;


const Section = styled.div`
  display: flex;
  width:70%;
  flex-direction: column;
  align-self:center;
  margin-bottom: 20px;
`;
const CardComponent = styled.div`
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;
const SubscribeButton = styled(Button)`
&& {

  color:green;
  border: 1px solid green;

  font-size: 20px;
  &:hover{
    cursor: pointer;
  }
}
`;

const Row = styled(Section)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  @media(max-width: 800px){
    width: 90%;
    flex-direction: column;
  }
  @media(min-width: 1400px){
    width: 30%;
  }
`;

const SectionTitle = styled.h2`
  color: #480CA8;
`;
const SubscriptionSectionStyled = styled.div`
  width: 30%;
  align-self:center;
  @media(max-width: 800px){
    width: 70%;
  }
`;
const SubscriptionTypeValue = styled.p`
  font-size: 25px;
`

const Account: React.FC = () => {
  const [email, setEmail] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const {token, user} = useUser();
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const subscribionButton = () =>{
    if(subscriptionType === 'Free Tier'){
      return <SubscribeButton  onClick = { () => navigate('/PremiumPage')}>Go Pro</SubscribeButton >
    }
    else{
      return(
        <UnsubscribeModal/>
      )
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/get_account_information?token=${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers as needed
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const data = await response.json();
        setSubscriptionType(data.subscriptionType);
        setEmail(data.email);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData(); 
  
  }, [token]);
  

  return (
    <Container>
      <Row>
        <div>
          <SectionTitle>Email</SectionTitle>
        <CardComponent>
          {(loading)?(
            <><CircularProgress/></>
          ):(
            <StyledValue>{email}</StyledValue>
          )}
        </CardComponent>
        </div>

        <div>
            <SectionTitle>Password</SectionTitle>
          <CardComponent>
            <ChangePasswordModal/>
          </CardComponent>
        </div>
      </Row>
        
      <SubscriptionSectionStyled>
        <div>
            <SectionTitle>Subscription</SectionTitle>
          <CardComponent>
              {(loading)?(
                <><CircularProgress/></>
              ):(
                <SubscriptionTypeValue>{subscriptionType}</SubscriptionTypeValue>
              )}
              {subscribionButton()}

          </CardComponent>
        </div>
      </SubscriptionSectionStyled>
    </Container>
  );
};

export default Account;
