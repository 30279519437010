import React from 'react';
import styled from 'styled-components';

interface TabProps {
  label: string;
  children: React.ReactNode;
  activeTab: string;
}
const Wrapper = styled.div`
  min-height:300px;
`;

const Tab: React.FC<TabProps> = ({ label, children, activeTab }) => {
  if (activeTab !== label) {
    return null;
  }

  return <Wrapper>{children}</Wrapper>;
};

export default Tab;
