import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import {API_URL} from '../../urls';


const Wrapper = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const FormContainer = styled.div`
    border: lightgrey 1px solid;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const StyledCircularProgress = styled(CircularProgress)`
    padding: 10%;
    margin: auto;
`;

const ButtonStyled = styled(Button)`
  && {
    background-color: #480CA8;
    color: white;
    &:hover {
      background-color: #4a2ea5;  // Adjusted hover color
      
    }
  }
`;


const ResetPassword = () =>{
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    const [ loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');


    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value);

    const handleKeyPress = (event: React.KeyboardEvent, handler: () => void) => {
      if (event.key === 'Enter') {
        handler();
      }
    }
 
    const handlePasswordReset = () =>{
      fetch(`${API_URL}/reset_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token , newPassword}),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message); // Log the response message
        // Add any additional logic based on the response from the backend
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle the error as needed
      });
      
    }

      // need to add: 1) Check if token is still active(2hours is the maximum amount of time) 2) add the input field and button. 3) if token is not active change something

      if (loading) {
        return <Wrapper><StyledCircularProgress size={100} disableShrink color="secondary" /></Wrapper>
      }
    
    return(
        <Wrapper>
            <p>Write your new password</p>
            <FormContainer>
                <Input
              type="password"
              placeholder="Password"
              value={newPassword}
              onChange={handlePasswordChange}
              onKeyDown={(e:any) => handleKeyPress(e,handlePasswordReset)}
              />
                <br/>
                <ButtonStyled onClick={handlePasswordReset}>Change Password</ButtonStyled>
            </FormContainer>
        </Wrapper>
    )

}
export default ResetPassword;