import React, { useRef, ReactNode, useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Button } from '../Button';
import { NavigationLink } from '../NavigationLink';
import styled from 'styled-components';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import OurEffect from '../OurEffects/OurEffects';
import Fame from '../OurEffects/Fame';
import Fiction from '../OurEffects/Fiction';
import Arts from '../OurEffects/Arts';
import Fashion from '../OurEffects/Fashion';
import ContinueCreation from '../ContinueCreationButton/ContinueCreation';
import LogInPage from '../LogIn/LogInPage';
import {BiSolidDownArrow} from 'react-icons/bi';
import Footer from '../Footer/Footer';
import { BrowserView, MobileView } from 'react-device-detect';

interface SectionComponentProps {
    forwardedRef: React.RefObject<HTMLDivElement>; 
    children: ReactNode; 
}
const StyledSection = styled.div`
    width: 100vw;
    overlow:hidden;
`;
const SectionComponent: React.FC<SectionComponentProps> = ({ forwardedRef, children }) => {
    return (
      <StyledSection ref={forwardedRef}>
        {children}
      </StyledSection>
    );
  };
const GlobalStyle = createGlobalStyle`
    body{
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-padding-top: 80px;
        @media(max-width:700px){
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }
`;

const Wrapper = styled.div`
    width: 100%;
    text-align: center;
    overlow:hidden;
`;
const EffectsWrapper = styled.div`
    color: #9979F9;
`;
const StyledMobileView = styled(MobileView)`
    height:100vh;
`;
const LandingPageNavigationButton = styled.button`
    border-radius:50%;
    width:4vw;
    height: 4vw;
    color: midnightBlue;
    position:fixed;
    bottom: 15px;
    z-index:20;
    color:white;
    border: solid midnightBlue 2px;
    cursor: pointer;
    @media(max-width: 700px){
        width: 55px;
        height: 55px;
        right: 43%;
    }
`;
const NavigationArrow = styled(BiSolidDownArrow)`
    font-size: 2vw;
    color: midnightBlue;
    @media( max-width: 700px){
        font-size: 20px;
    }
`;
type RefType = React.RefObject<HTMLElement>;

const LandingPage = () => {
    const section1Ref = useRef<HTMLDivElement | null>(null);
    const fictionRef = useRef<HTMLDivElement | null>(null);
    const artsRef = useRef<HTMLDivElement | null>(null);
    const fashionRef = useRef<HTMLDivElement | null>(null);
    const fameRef = useRef<HTMLDivElement | null>(null);
    const logInRef = useRef<HTMLDivElement | null>(null);
    const [scrolling, setScrolling] = useState(false);

    const sectionRefs: RefType[] = [section1Ref, fictionRef, artsRef, fashionRef, logInRef];
    const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
    
    // the following handles wheel scroll events

    useEffect(() => {
        const handleWheelScroll = (e: WheelEvent) => {
            e.preventDefault();
            if (scrolling) {
                return; // If scrolling is in progress, ignore the new scroll event
              }
        
              setScrolling(true);
        
            // Determine the scroll direction
            const deltaY = e.deltaY;
            const scrollDirection = deltaY > 0 ? 'down' : 'up';
        
            // Calculate the current section index
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const sectionIndex = Math.floor(scrollTop / windowHeight);
        
            // Calculate the next section index based on the scroll direction
            let nextSectionIndex;
            if (scrollDirection === 'down') {
                nextSectionIndex = sectionIndex === sectionRefs.length - 1 ? sectionRefs.length - 1 : (sectionIndex + 1) % sectionRefs.length;
            } else {
                nextSectionIndex = sectionIndex === 0 ? sectionRefs.length - 1 : sectionIndex - 1;
            }
        
            // Prevent scrolling beyond boundaries
            if(scrollDirection === 'down' && sectionIndex === sectionRefs.length - 1){
                nextSectionIndex = sectionRefs.length-1;
            }
            if(scrollDirection === 'up' && sectionIndex === 0){
                nextSectionIndex = 0;

            }
            
            // Scroll to the next section
            handleScroll(nextSectionIndex);
            setTimeout(() => {
                setScrolling(false);
              }, 800);
        
        };

  // Disable wheel event handling on mobile devices
        if (!('ontouchstart' in window)) {
            document.addEventListener('wheel', handleWheelScroll, { passive: false });
        }

        return () => {
            document.removeEventListener('wheel', handleWheelScroll);
        };
    }, [scrolling, window.innerWidth]);


    // the following handles swiping events


    // the following takes care of arrow clicks
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                handleArrowSwipe('up');
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                handleArrowSwipe('down');
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [window.innerWidth]);

    const handleArrowSwipe = (direction: 'up' | 'down') => {
        console.log('handleArrowSwipe');
    const sectionIndex = currentSectionIndex;
        let nextSectionIndex;

        if (direction === 'down') {
            nextSectionIndex = sectionIndex === sectionRefs.length - 1 ? 0 : sectionIndex + 1;
        } else {
            nextSectionIndex = sectionIndex === 0 ? sectionRefs.length - 1 : sectionIndex - 1;
        }

        if (nextSectionIndex !== currentSectionIndex) {
            setCurrentSectionIndex(nextSectionIndex);
            handleScroll(nextSectionIndex);
        }
    };
    const handleClick = () =>{
        if(currentSectionIndex === sectionRefs.length-1){
            handleScroll(0);
            setCurrentSectionIndex(0);

        }else{
            handleScroll(currentSectionIndex+1);
            setCurrentSectionIndex(currentSectionIndex+1);

        }

    };

    
    const handleScroll = (index: number) => {
        const duration = 400; // Set your desired duration in milliseconds
        const startTime = performance.now();
        const startTop = window.scrollY;
        const targetTop = index === 0 ? 0 : sectionRefs[index]?.current?.offsetTop ?? 0;
      
        const cubicDeceleration = (t: number) => 1 - Math.pow(1 - t, 3);
      
        const scrollStep = (timestamp: number) => {
          const progress = Math.min(1, (timestamp - startTime) / duration);
          const deceleratedProgress = cubicDeceleration(progress);
          const scrollTop = startTop + deceleratedProgress * (targetTop - startTop);
          window.scrollTo(0, scrollTop);
      
          if (progress < 1) {
            window.requestAnimationFrame(scrollStep);
          }
        };
      
        window.requestAnimationFrame(scrollStep);
      };
    


    return (
        <Wrapper>
        
            <GlobalStyle/>
                <SectionComponent forwardedRef={section1Ref}  >
                    <Section1 /> 
                </SectionComponent>
                <EffectsWrapper>
                    <SectionComponent forwardedRef={fictionRef} >
                        <Fiction  />
                    </SectionComponent>
                    <SectionComponent forwardedRef={artsRef} >
                        <Arts  />
                    </SectionComponent>
                    <SectionComponent forwardedRef={fashionRef} >
                        <Fashion />
                    </SectionComponent>
                    <SectionComponent forwardedRef={logInRef} >
                        <LogInPage/>
                    </SectionComponent>
                </EffectsWrapper>
        </Wrapper>
    );
};

export default LandingPage;
