import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {styled} from 'styled-components';
import { API_URL } from '../../urls';
import {useUser} from '../../Hooks/Context/UserContext';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: 2,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 7,
};


const UnsubscribeButton = styled(Button)`
&& {

  color:red;

  font-size: 10px;
  &:hover{
    cursor: pointer;
  }
}
`;
const ButtonStyled = styled(Button)`
  && {
    background-color: #480CA8;
    color: white;
    &:hover {
      background-color: #4a2ea5;  // Adjusted hover color
    }
  }
`;

const UnsubscribedButton = styled(ButtonStyled)`
  && {
    background-color: white;
    color: #480CA8;
    border: 1px solid #480CA8;
    &:hover {
      background-color: white;  // Adjusted hover color
    }
  }
`;

export default function UnsubscribeModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {token} = useUser();


  const handleUnsubscribe = () => {
    // Make a POST request to your Flask backend with the token
    fetch(`${API_URL}/cancel_subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })
    
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to unsubscribe');
        }
        // Handle success (e.g., show a success message, update UI, etc.)
        console.log('Successfully unsubscribed');
        handleClose();
      })
      .catch(error => {
        // Handle error (e.g., show an error message)
        console.error('Error unsubscribing:', error.message);
      });
  };

  return (
    <div>
      <UnsubscribeButton onClick={handleOpen}>Unsubscribe</UnsubscribeButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
          sx={style}
          alignItems="center"
          display="flex"
          flexDirection='column'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to unsubscribe?
          </Typography>
          <br/>
          <ButtonStyled onClick={handleClose}>Keep subscription</ButtonStyled>
          <br/>
          <UnsubscribedButton  onClick={handleUnsubscribe}>Unsubscribe</UnsubscribedButton>
        </Box>
      </Modal>

    </div>
  );
}