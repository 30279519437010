import React, { FC } from 'react';
import styled from 'styled-components';
import LoginForm from '../LogIn/LoginForm';
import { NavigationLink } from '../NavigationLink';
import { IoHome } from "react-icons/io5";



interface LoginOverlayProps {
    onLoginSuccessfull: () => void;
}

const Overlay = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width:30%;
  height:80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media( max-width: 800px){
    width:70%;
    height:70%;
  }
`;
const NavigationWrapper = styled.div`
  text-align:left;
  align-self: left;
  float: left;
`


const LoginOverlay: FC<LoginOverlayProps> = ({ onLoginSuccessfull }) => {
  return (
    <Overlay>
      <FormContainer>
        <NavigationWrapper>
          <NavigationLink variant='inPage' to='/'> <IoHome /></NavigationLink>
        </NavigationWrapper>
        <LoginForm  navigateTo='/Create'/>
      </FormContainer>
    </Overlay>
  );
};

export default LoginOverlay;
