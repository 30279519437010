import React from 'react';
import styled from 'styled-components';
import Fiction from './Fiction';
import Arts from './Arts';
import Fashion from './Fashion';


const Wrapper = styled.div`
    width:100%;
    color: #9979F9;
`;
const OurEffect = () =>{

      
    return(
        <>
        <Wrapper>
            <Fiction/>
            <Arts/>
            <Fashion/>

        </Wrapper>
        </>
        
    )

}
export default OurEffect;