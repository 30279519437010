import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

type LinkVariants = 'navigation' | 'footer' | 'logo'| 'mobileLink' | 'inPage'| 'primary';

type LinkProps = {
  variant: LinkVariants;
  to: string;
  children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

type LinkVariantDetails = {
  color: string;
  backgroundColor: string;
  fontSize: string;
  fontWeight: string;
  textDecoration: string;
  hoverColor: string;
  padding: string;
  margin: string;
  border: string;
};



const variantDetails: Record<LinkVariants, LinkVariantDetails> = {
  navigation: {
    color: '#480CA8',
    backgroundColor: 'rgba(255,255,255,0.4)',
    fontSize: '1.8rem',
    textDecoration: 'none',
    hoverColor: '#9960f4',
    fontWeight: 'regular',
    padding: '5px 1px',
    margin: '0px 1.5vw 0px 1.5vw',
    border: '0px',
  },
  footer: {
    color: 'white',
    backgroundColor:'inherit',
    fontSize: '16px',
    textDecoration: 'none',
    hoverColor: 'lightgrey',
    fontWeight: 'regular',
    padding: '0px',
    margin: '0px 5vw 0px 0px',
    border: '0px'
  },
  logo:{
    color: '#480CA8',
    backgroundColor: 'white',
    fontSize: '1.7rem',
    textDecoration: 'none',
    hoverColor: '#9960f4',
    fontWeight: 'regular',
    padding: '0px',
    margin: '0px 2.5vw 0px 2.5vw',
    border: '0px'
  },
  mobileLink:{
    color: '#480CA8',
    backgroundColor: 'white',
    fontSize: '20px',
    textDecoration: 'none',
    hoverColor: '#9960f4',
    fontWeight: 'regular',
    padding: '10px 0px 0px 0px',
    margin: '0px',
    border: '0px'

  },
  inPage:{
    color: '#480CA8',
    backgroundColor: 'white',
    fontSize: '20px',
    textDecoration: 'none',
    hoverColor: '#9960f4',
    fontWeight: 'regular',
    padding: '5px 5px 5px 5px',
    margin: '0px',
    border: '1px solid #480CA8;'
  },
  primary: {
    color: '#480CA8',
    backgroundColor: 'white',
    fontSize: '20px',
    fontWeight: 'normal', // this should match with the 'font' property you provided
    textDecoration: 'none',
    hoverColor: '#9960f4', // I'm using the hover color from the existing variants, update this to match your design
    padding: '5px 10px', // Padding isn't specified in your styles, so I'm setting it to '0px'
    margin: '0px', // Margin isn't specified in your styles, so I'm setting it to '0px'
    border: '1px solid #480CA8',
  }

};

const StyledLink = styled(Link)<LinkProps>`
  ${props => {
    const { color, fontSize, fontWeight, textDecoration, hoverColor, backgroundColor, padding, margin, border } = variantDetails[props.variant];
    return css`
      color: ${color};
      background-color: ${backgroundColor};
      font-size: ${fontSize};
      text-decoration: ${textDecoration};
      font-weight:${fontWeight};
      padding: ${padding};
      margin: ${margin};
      border: ${border};
      border-radius: 35px;
      
      &:hover {
        color: ${hoverColor};
      }
      &:active {
        color: ${hoverColor};
      }
    `;
  }}
  cursor: pointer;
  font-family: MikelaRegular;
`;


export const NavigationLink: React.FC<LinkProps> = ({ variant, children, ...props }) => (
  <StyledLink variant={variant} {...props}>
    {children}
  </StyledLink>
);
