import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import FilesSection from './CreateComponents/FileSection/FileSection';
import { motion, AnimatePresence } from 'framer-motion';
import Finished from './CreateComponents/Finished';
import {useCreationProcessContextValue} from '../../Hooks/Context/CreationProcessContext';
import { Button } from '../Button';
import EffectGroupSlider from './CreateComponents/EffectGroupSlider/EffectGroupSlider';
import { useUser } from '../../Hooks/Context/UserContext';
import LoginOverlay from './LoginOverlay';
import { useEffectsDataContext } from '../../Hooks/Context/EffectsDataContext';
import CircularProgress from '@mui/material/CircularProgress';
import FloatingActionButtons from './CreateComponents/FloatingActionButtons/FloatingActionButtons';
import ExtraSettingsWidget from './CreateComponents/ExtraSettingsWidget/ExtraSettingsWidget';

interface StyledEffectGroupSliderProps {
    backgroundColor: string; 
  }

const Wrapper = styled.div`
    text-align:center;
    width: 100%;
    display: inline-block;
    margin-bottom:50px;
`;
const FileSectionWrapper = styled.div`
    @media(max-width:800px){
        width: 90%;
    }
`;
const CompleteCreationDiv = styled.div`
    position:fixed;
    top: 25%;
    right: 10%;
    background-color: rgb(72, 12, 168, 0.7);
    width:15vw;
    padding:10px;
    border-radius: 10px;
    z-index:1000;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    padding: 10px 0px;
    @media(max-width:800px){
        top: 15%;
        right:4%;
        width:30vw;
    }

`;
const SelectedEffectDiv = styled.div`
    position:absolute;
    top: 35%;
    right: 10%;
    background-color: rgb(72, 12, 168, 0.7);
    width:10vw;
    padding:10px;
    border-radius: 10px;
    z-index:1000;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    @media(max-width:800px){
        top: 20%;
        right:2%;
        width:30vw;
    }
`;
const StyledEffectGroupSlider = styled.div<StyledEffectGroupSliderProps>`
    background-color: ${(props) => props.backgroundColor};
    border-radius: 10px;
    width: 33%;
    @media(max-width:800px){
        width: 90%;
    }
`;
const AlignmentDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Create: React.FC = () => {
    const [step, setStep] = useState(2);
    const [hasFinished, setHasFinished] = useState<boolean>(false);
    const {effectName,  selectedImage, addImage, selectedEffectId, completedCreation, imageId, loading } = useCreationProcessContextValue();
    const [selectedFile, setSelectedFile] = useState(selectedImage);
    const [hasRequestedImage, setHasRequestedImage] = useState<boolean>(false);
    const {token, user} = useUser();
    const [showLoginOverlay, setShowLoginOverlay] = useState(token === null || token ==='');

    const { getEffectNameById } = useEffectsDataContext();
    const effect_Name = getEffectNameById(selectedEffectId);

    const [effectButtonClicked, setEffectButtonClicked] = useState<boolean>(false);

    const handleEffectButtonClicked = (newValue:boolean) => {
        setEffectButtonClicked(newValue);
      };

    const [color, setColor] = useState('white');

    useEffect(() => {
        if (effectButtonClicked) {
        setColor('rgba(0,0,0,0.1)');
        const timeoutId = setTimeout(() => {
            setColor('white');
            setEffectButtonClicked(false);
        }, 2000);

        return () => clearTimeout(timeoutId);
        }
    }, [effectButtonClicked]);

    useEffect(()=>{
        setSelectedFile(selectedImage);
    },[selectedImage])

    useEffect(()=>{
        if(token !== null && token !== ''){
            setShowLoginOverlay(false);
        }else{
            setShowLoginOverlay(true)
        }
    },[token]);

      useEffect(() => {
        console.log('the selected file ', selectedFile);
      }, [selectedFile]); 
    useEffect(()=>{
        if(selectedEffectId !== '' && selectedImage !== null){
            setHasFinished(true);
        } else {
            setHasFinished(false);
        }
    },[selectedEffectId, selectedImage]);

    useEffect(()=>{
        if(selectedEffectId === ''){
            setHasRequestedImage(false);
        } 
    },[selectedEffectId]);


    const handleFileSelection = (file : any) => {
        setSelectedFile(file);
        addImage(file);
        setStep(3);
    };
    const handleSettingStep = (number : number) =>{
        setStep(number);
    }

    const handleFileDeselected = () => {
        setSelectedFile(null);
    };
    const handleImageCreationRequest = () =>{  
        setHasRequestedImage(true);
        completedCreation();
    };

    const stepAnimationVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 }
    }
    

    return (
        <Wrapper >
            <ExtraSettingsWidget/>
        <FloatingActionButtons onEffectButtonClicked={setEffectButtonClicked} effectButtonClicked={effectButtonClicked}/>
            {(!hasRequestedImage) ? (
                <>
                    <FileSectionWrapper>
                        <AnimatePresence mode='wait'>
                            <motion.div
                                key={step}
                                variants={stepAnimationVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                            >
                                <FilesSection
                                    onFileSelect={handleFileSelection}
                                    onFileDeselected={handleFileDeselected}
                                    file={selectedFile}
                                    setStep={handleSettingStep}
                                />
                            </motion.div>
                        </AnimatePresence>
                    </FileSectionWrapper>
                    <AlignmentDiv>
                        <StyledEffectGroupSlider backgroundColor={color}><EffectGroupSlider /></StyledEffectGroupSlider>
                    </AlignmentDiv>
            </>
            ) : (
                <>
                </>
            )}
            
            {(selectedEffectId !== '' && !hasFinished)?(
            <SelectedEffectDiv>
                <h4>You chose {effectName}</h4>
                <span>Now Pick an Image to continue</span>
            </SelectedEffectDiv>
            ):(
            <></>
            )}

             {showLoginOverlay && <LoginOverlay onLoginSuccessfull = {() => setShowLoginOverlay(false)} />}
            
        </Wrapper>
    );
};

export default Create;
