import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';


export interface User {
  email: string;
  name: string;
  type_of_login: string;
  google_id: string;
  authenticated: boolean;
  createdat: Date;
  tier: string;
}

interface UserContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  user_id: string;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [user_id, setUserId] = useState<string>('');
  const [token, setToken] = useState<string>('');



  useEffect(() => {
    localStorage.setItem('generate-me-user', JSON.stringify(user));
    localStorage.setItem('generate-me-token', token);

  }, [user, token]);


  return (
    <UserContext.Provider value={{ user, setUser, user_id, setUserId , token, setToken}}>
      {children}
    </UserContext.Provider>
  );
}


export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
