import StripeContainer from './StripeContainer';

const PremiumPage = () => {
  return (
    <div>
      {/* Other premium page content */}
      <StripeContainer />
    </div>
  );
};

export default PremiumPage;