import React from 'react';
import styled from 'styled-components';
import { NavigationLink } from '../NavigationLink';
import LandingPage from './LandingPage2/EffectSliderForLandingPage';
import CarouselPage from './3DCarousel/CarouselPage';

import '../../font.css';


const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    @media(max-width: 800px){
        height: 90vh;
    }
`;

const Section1 = () => {
    return(
        <Wrapper>  
            <CarouselPage/>
        </Wrapper>
    )
}
export default Section1;
