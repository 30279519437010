import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {styled} from 'styled-components';
import StripePricingTable from './StripePricingTable';

const stripePromise = loadStripe('pk_live_51ODu2uAC5xIxVIOp2wRz4Bz57fIMlLTw4YNfbNdOEhUfwsU90oPCAEd8YhuGWmkkBmK6KoFji3s3I0jZRQqCKC7Q00H5uZQZQk');

const Wrapper = styled.div`
    box-shadow: 5px 5px 13px 0px rgba(72,12,171,0.98);
    width: 30%;
    margin: auto;
    @media(max-width: 800px){
        width: 90%;
    }
`;


const StripeContainer = () => {
  return (
    <Elements stripe={stripePromise}>
        <Wrapper>
            <StripePricingTable/>
        </Wrapper>
    </Elements>

  );
};

export default StripeContainer;