import React from 'react';
import styled from 'styled-components';

interface TabHeaderProps {
  label: string;
  activeTab: string;
  onClick: (label: string) => void;
}

const ButtonWrapper = styled.button`
    background-color:unset;
    height:40px;
    border-width: 0px 0px 2px 0px;
    border-radius: 15%;
    margin-right: 40px;
    border-color: #9960f4;
    cursor: pointer;
    font-size: 1.4rem;
    font-family:MikelaRegular;
`;

const TabHeader: React.FC<TabHeaderProps> = ({ label, activeTab, onClick }) => {
  
  return (
    <ButtonWrapper onClick={() => onClick(label)} style={{ opacity: activeTab === label ? 1 : 0.5 }}>
      {label}
    </ButtonWrapper>
  );

};

export default TabHeader;
